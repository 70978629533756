import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import moment from "moment";
import { formatterCreateAt } from "components/Function";
import { addUserShop } from "actions/userActions";

function Main({ user, formatterCreateAt, addUserShop }) {
    const { package: userPackage } = user;
    const { t } = useTranslation();
    const [shopNumber, setShopNumber] = useState("1");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        const dateDiff = moment(+user.expired_at).diff(moment(), "days");
        if (dateDiff <= 0) {
            return Swal.fire(t("Error"), t("Please buy package to add shop"), "error");
        }

        const priceBuy = Math.ceil((((2 / 30) * (dateDiff + 1) * Math.abs(+shopNumber)) + Number.EPSILON) * 100) / 100;

        Swal.fire({
            title: t("Are you sure?"),
            text: t(`You confirm the purchase for ${priceBuy}$`),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sure"),
            cancelButtonText: t("Cancel"),
        }).then(async (result) => {
            if (result.value) {
                setLoading(true);
                await addUserShop({ number: shopNumber });
                setLoading(false);
            }
        });
    };

    return (
        <Fragment>
            <div className="card card-flush">
                <div className="card-header">
                    <div className="card-title">
                        <h2 className="mb-0">Account Package: {userPackage?.name}</h2>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="d-flex flex-column text-gray-600">
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3" />
                            Price Buy: {userPackage?.price}$
                        </div>
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3" />
                            Shop Limit: {user.limit_shop}
                        </div>
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3" />
                            Staff Limit: {user.limit_staff}
                        </div>
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3" />
                            Package Expired: {formatterCreateAt(+user.expired_at)}
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-flush mt-4">
                <div className="card-body ">
                    <div className="d-flex flex-column">
                        <label className="fs-5 fw-semibold mb-2">Number Shop Add</label>
                        <input
                            className="form-control"
                            type="number"
                            value={shopNumber}
                            onChange={(e) => setShopNumber(e.target.value)}
                            onBlur={(e) => {
                                if (!+e.target.value) setShopNumber("1");
                            }}
                        />
                    </div>
                </div>

                <div className="card-footer pt-0">
                    <button
                        disabled={loading}
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-primary btn-active-primary"
                    >
                        Add Shop
                    </button>
                </div>
            </div>
        </Fragment>
    );
}

Main.propTypes = {};
const mapStateToProps = ({ user }) => ({ user: user?.user });
const mapDispatchToProps = { formatterCreateAt, addUserShop };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
