import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { getStaffs } from "actions/teamActions";
import { getCategoriesProduct, deleteCategoriesProduct } from "actions/categoryProductAction";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import SelectBox from "components/elements/selectBox";
import { ModalAddCategoriesProduct, ModalEditCategoriesProduct } from "./modal";


function CategoriesProductList(props) {
    const [isOpenModalAddCategoriesProduct, setIsOpenModalAddCategoriesProduct] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [toggleCard, setToggleCard] = useState(false);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [rowSelect, setRowSelect] = useState(null);
    const { t } = useTranslation();

    var { list, totalsize, isLoading } = props.categoryProduct;
    var { getStaffs, getCategoriesProduct,deleteCategoriesProduct } = props;

    useEffect(() => {
        getCategoriesProduct({ page, sizeperpage: sizePerPage, search: searchText, filter });
    }, [getCategoriesProduct, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    const onClickOpenModalAddCategoriesProduct = () => {
        setIsOpenModalAddCategoriesProduct(!isOpenModalAddCategoriesProduct);
    };
    const onClickDeleteCategoriesProduct = (row = {}) => {
        row = {
            ...row,
            page, sizeperpage: sizePerPage, search: searchText, filter
        }
        deleteCategoriesProduct(row);
    };

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
        },
        {
            dataField: "created_by",
            text: t("Created By"),
            sort: true,
            formatter: (cell, row) => staffs?.find((v, i) => v?.value === cell)?.label || "me",
        }, {
            dataField: '-',
            text: t("Actions"),
            formatter: (cell, row, rowIndex) =>
                <Fragment>
                    <button onClick={() => {
                        setRowSelect({
                            ...row,
                            page, sizeperpage: sizePerPage, search: searchText, filter
                        })
                    }} className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Edit")}>
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </button>
                    <button onClick={() => { onClickDeleteCategoriesProduct(row) }} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Delete")}>
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button>
                </Fragment>

        }
    ];

    const sizePerPageList = [
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 10,
        },
    ];

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex justify-content-between">
                    <div className="d-flex">
                        <button onClick={() => { onClickOpenModalAddCategoriesProduct() }} type="button" className="btn btn-primary btn-sm me-1">
                            <i className="ki-outline ki-plus fs-2"></i> {t("Add Category")}
                        </button>
                        <SelectBox
                            options={staffs}
                            name="filter_staff"
                            className="btn btn-sm"
                            value={filter.staff || ""}
                            onChange={(data) => {
                                setFilter((prev) => ({
                                    ...prev,
                                    staff: data.value || "",
                                }));
                                setPage(1);
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(10);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };


    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="card mb-5 mb-xl-10">
                <div
                    className={cx("card-header collapsible cursor-pointer rotate", {
                        active: !toggleCard,
                        collapsed: toggleCard,
                    })}
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_docs_card_collapsible"
                    onClick={() => setToggleCard(!toggleCard)}
                >
                    <h3 className="card-title">{t("List Category")}</h3>
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1"></i>
                    </div>
                </div>
                <div id="kt_docs_card_collapsible" className={cx("collapse", { show: !toggleCard })}>
                    <div className="card-body p-9">
                        <div className="al-variants-table al-max-height-table-cus">
                            <ReactTable
                                columns={columns}
                                data={list}
                                groupButton={groupButton}
                                handleTableChange={handleTableChange}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                alClassName="table-layout-inherit"
                                sizePerPageList={sizePerPageList}
                            />
                        </div>
                        {isOpenModalAddCategoriesProduct ? <ModalAddCategoriesProduct openModal={onClickOpenModalAddCategoriesProduct} isOpenModalAddCategoriesProduct={isOpenModalAddCategoriesProduct} /> : ''}
                        <ModalEditCategoriesProduct
                            data={rowSelect}
                            onClose={() => setRowSelect(null)}
                            onSubmit={() => {
                                getCategoriesProduct({ page, sizeperpage: sizePerPage, search: searchText, filter });
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

CategoriesProductList.propTypes = {
    getStaffs: PropTypes.func.isRequired,
    getCategoriesProduct: PropTypes.func.isRequired,
    deleteCategoriesProduct: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    categoryProduct: state.categoryProduct,
});
const mapDispatchToProps = {
    getStaffs,
    getCategoriesProduct,
    deleteCategoriesProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesProductList);
