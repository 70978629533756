import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getTotalOrder =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/total?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const getDataChart =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/overview?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const getOnHold =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/on-hold?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

export { getTotalOrder, getDataChart, getOnHold };
