import { combineReducers } from "redux";
import userReducer from "./userReducer";
import websiteReducer from "./websiteReducer";
import appReducer from "./appReducer";
import paymentReducer from "./paymentReducer";
import emailReducer from "./emailReducer";
import promotionReducer from "./promotionReducer";
import notificationReducer from "./notificationReducer";
import shopReducer from "./shopReducer";
import productReducer from "./productReducer";
import productTemplateReducer from "./productTemplateReducer";
import packageReducer from "./packageReducer";
import orderReducer from "./orderReducer";
import teamReducer from "./teamReducer";
import statementReducer from "./statementReducer";
import designReducer from "./designReducer";
import productTikTokReducer from "./productTikTokReducer";
import printerReducer from "./printerReducer";
import trendingReducer from "./trendingReducer";
import categoryProductReducer from "./categoryProductReducer";
import returnOrdersReducer from "./returnOrdersReducer";
import productScheduleReducer from "./productScheduleReducer";
import amazonProductReducer from "./amazonProductReducer";
import tiktokTopSalesReducer from "./tiktokTopSalesReducer";
import tiktokBreakoutProductReducer from "./tiktokBreakoutProductReducer";
import tiktokNewProductReducer from "./tiktokNewProductReducer";
import tiktokHotProductReducer from "./tiktokHotProductReducer";
import extensionReducer from "./extensionReducer";
import conversationReducer from "./conversationReducer";
import messageReducer from "./messageReducer";
import shopMessageReducer from "./shopMessageReducer";
import shopPromotionReducer from "./shopPromotionReducer";
import cancelOrdersReducer from "./cancelOrdersReducer";
import transitionReducer from "./transitionReducer";
import aiReducer from "./aiReducer";

export default combineReducers({
    user: userReducer,
    website: websiteReducer,
    app: appReducer,
    payment: paymentReducer,
    email: emailReducer,
    promotion: promotionReducer,
    notification: notificationReducer,
    shop: shopReducer,
    product: productReducer,
    productTemplate: productTemplateReducer,
    package: packageReducer,
    order: orderReducer,
    team: teamReducer,
    statement: statementReducer,
    design: designReducer,
    producttiktok: productTikTokReducer,
    printer: printerReducer,
    trending: trendingReducer,
    categoryProduct: categoryProductReducer,
    returnOrders: returnOrdersReducer,
    productSchedule: productScheduleReducer,
    amazonTrending: amazonProductReducer,
    product_rising_rank: tiktokTopSalesReducer,
    product_sales_rising_rank: tiktokBreakoutProductReducer,
    product_new_rank: tiktokNewProductReducer,
    product_hot_rank: tiktokHotProductReducer,
    extension: extensionReducer,
    conversation: conversationReducer,
    message: messageReducer,
    shopMessage: shopMessageReducer,
    shopPromotion: shopPromotionReducer,
    cancelOrders: cancelOrdersReducer,
    adminTransition: transitionReducer,
    ai: aiReducer,
});
