import axios from "axios";
import Swal from "sweetalert2";
import { t } from "i18next";
import { tokenConfig } from "./websiteActions";

const getTransitions =
    ({ page = 1, sizeperpage = 10, search = "", filter = {} }) =>
    (dispatch, getState) => {
        var param_search = "";
        if (search !== "") {
            param_search = `&search=${search}`;
        }
        filter = new URLSearchParams(filter).toString();
        if (filter) filter = `&${filter}`;

        dispatch({ type: "LOADING_ADMIN_TRANSITION", payload: true });
        return axios
            .get(
                `/api/user/admin/user-transition?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`,
                tokenConfig(getState)
            )
            .then((res) => {
                dispatch({ type: "LOADING_ADMIN_TRANSITION", payload: false });

                if (res.data.status === 200) {
                    dispatch({
                        type: "ADMIN_TRANSITION_USER",
                        payload: res.data.data,
                    });
                    return res.data.data;
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                    return [];
                }
            })
            .catch((err) => {
                dispatch({ type: "LOADING_ADMIN_TRANSITION", payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), "error");
                return [];
            });
    };

export { getTransitions };
