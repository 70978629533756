import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { addRecharge } from "actions/rechargeActions";

function Modaladdprinter({ addRecharge, onSubmit }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const { t } = useTranslation();

    const handleSubmit = async () => {
        setIsLoading(true);
        const result = await addRecharge(formData);
        if (result) {
            setIsOpen(false);
            onSubmit?.();
        }
        setIsLoading(false);
    };

    const handleFormChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    return (
        <Fragment>
            <button
                onClick={() => {
                    setIsOpen(true);
                }}
                type="button"
                className="btn btn-primary btn-sm me-1"
            >
                <i className="ki-outline ki-plus fs-2"></i> {t("Add")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add Printer")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Transition")}</span>
                        </label>
                        <input
                            type="tId"
                            className="form-control"
                            value={formData.tId}
                            placeholder={t("Transition")}
                            name="tId"
                            onChange={handleFormChange}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    printer: state.printer,
});
const mapDispatchToProps = {
    addRecharge,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modaladdprinter);
