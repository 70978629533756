import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getCategoriesProduct =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_CATEGORY_PRODUCT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            return axios
                .get(`/api/categoryProduct?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_CATEGORY_PRODUCT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_CATEGORY_PRODUCT",
                            payload: res.data.data,
                        });
                        return res.data.data;
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                        return [];
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_CATEGORY_PRODUCT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                    return [];
                });
        };


const addCategoriesProduct = (data, closemodal) => (dispatch, getState) => {
    var body = {
        title: data.title,
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_CATEGORY_PRODUCT', payload: true });
            axios.post('/api/categoryProduct/add', body, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_CATEGORY_PRODUCT', payload: false });
                if (res.data.status === 200) {
                    closemodal();
                    dispatch(getCategoriesProduct(1, 10));
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_CATEGORY_PRODUCT', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};

const updateCategoriesProduct =
    (data) =>
        (dispatch, getState) => {
            const body = {
                ...data,
            };
            Swal.fire({
                title: t("Are you sure?"),
                text: t("Are you sure to edit?"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("Sure"),
                cancelButtonText: t("Cancel")
            }).then((result) => {
                if (result.value) {
                    dispatch({ type: 'LOADING_CATEGORY_PRODUCT', payload: true });
                    axios
                        .post("/api/categoryProduct/update", body, tokenConfig(getState))
                        .then((res) => {
                            dispatch({ type: "LOADING_CATEGORY_PRODUCT", payload: false });
                            if (res.data.status === 200) {
                                dispatch(getCategoriesProduct(1, 10));
                                Swal.fire("Success", res.data.message, "success");
                            } else {
                                Swal.fire("Error", res.data.message, "error");
                            }
                        })
                        .catch((err) => {
                            dispatch({ type: "LOADING_CATEGORY_PRODUCT", payload: false });
                            Swal.fire("Error", "An error occurred!", "error");
                        });
                }
            })
        };

const deleteCategoriesProduct = (data) => (dispatch, getState) => {
    var body = {
        _id: data._id
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete category?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_CATEGORY_PRODUCT', payload: true });
            axios.post('/api/categoryProduct/delete', body, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_CATEGORY_PRODUCT', payload: false });
                if (res.data.status === 200) {
                    dispatch(getCategoriesProduct({ page: data.page, sizeperpage: data.sizeperpage, search: data.search, filter: data.filter }));
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_CATEGORY_PRODUCT', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};
export { getCategoriesProduct, addCategoriesProduct, updateCategoriesProduct, deleteCategoriesProduct };
