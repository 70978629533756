import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getDesigns } from "actions/designAction";
import { getProductTikTokMainImageByApiProductId } from "actions/productTikTokActions";
import { formatterImage } from "components/Function";
import { Link } from "react-router-dom";

import { saveEditOrder } from "actions/orderActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Select from 'react-select';
import ReactImageMagnify from 'react-image-magnify';

function Modaleditdesign(props) {
    const [_id] = useState(props.editdesign._id);
    const [line_item_id] = useState(props.editdesign.line_item.id);
    const [product_id] = useState(props.editdesign.line_item.product_id);
    const [product_name] = useState(props.editdesign.line_item.product_name);
    const [main_images, setMainImages] = useState([]);
    const [design_fee, setDesignFee] = useState(props.editdesign.design_fee);
    const [mockup, setMockup] = useState(props.editdesign.mockup);
    const [design, setDesign] = useState(props.editdesign.design);
    const [design_match, setDesignMatch] = useState("");
    const [mockup_front, setMockupFront] = useState(props.editdesign.mockup?.[line_item_id]?.Front);
    const [mockup_back, setMockupBack] = useState(props.editdesign.mockup?.[line_item_id]?.Back);
    const [mockup_neck, setMockupNeck] = useState(props.editdesign.mockup?.[line_item_id]?.Neck);
    const [mockup_right, setMockupRight] = useState(props.editdesign.mockup?.[line_item_id]?.Right);
    const [mockup_left, setMockupLeft] = useState(props.editdesign.mockup?.[line_item_id]?.Left);
    const [mockup_aop_3d, setMockupAop3d] = useState(props.editdesign.mockup?.[line_item_id]?.AOP_3D);
    const [design_front, setDesignFront] = useState(props.editdesign.design?.[line_item_id]?.Front);
    const [design_back, setDesignBack] = useState(props.editdesign.design?.[line_item_id]?.Back);
    const [design_neck, setDesignNeck] = useState(props.editdesign.design?.[line_item_id]?.Neck);
    const [design_right, setDesignRight] = useState(props.editdesign.design?.[line_item_id]?.Right);
    const [design_left, setDesignLeft] = useState(props.editdesign.design?.[line_item_id]?.Left);
    const [design_aop_3d, setDesignAop3d] = useState(props.editdesign.design?.[line_item_id]?.AOP_3D);
    const [isEditMockupFront, setIsEditMockupFront] = useState(false);
    const [isEditMockupBack, setIsEditMockupBack] = useState(false);
    const [isEditMockupNeck, setIsEditMockupNeck] = useState(false);
    const [isEditMockupRight, setIsEditMockupRight] = useState(false);
    const [isEditMockupLeft, setIsEditMockupLeft] = useState(false);
    const [isEditMockupAop3d, setIsEditMockupAop3d] = useState(false);
    const [isEditDesignFront, setIsEditDesignFront] = useState(false);
    const [isEditDesignBack, setIsEditDesignBack] = useState(false);
    const [isEditDesignNeck, setIsEditDesignNeck] = useState(false);
    const [isEditDesignRight, setIsEditDesignRight] = useState(false);
    const [isEditDesignLeft, setIsEditDesignLeft] = useState(false);
    const [isEditDesignAop3d, setIsEditDesignAop3d] = useState(false);
    var { isLoading } = props.order;
    var { list } = props.design;
    var { isOpenModalEditDesign, openModal, getDesigns, saveEditOrder, getProductTikTokMainImageByApiProductId, formatterImage } = props;
    const { t } = useTranslation();
    const list_design = list.map((v, i) => {
        return { "label": `${v.title} (${v.keyword})`, "value": v._id, "mockup": v.mockup, "design": v.design };
    });

    useEffect(() => {
        getDesigns({ page: 1, sizeperpage: 9999 });
    }, [getDesigns]);

    useEffect(() => {
        (async () => {
            var res = await getProductTikTokMainImageByApiProductId(product_id);
            if (res?.producttiktok?.main_images?.length > 0) {
                setMainImages(res?.producttiktok?.main_images || []);
            }
        })();
    }, [getProductTikTokMainImageByApiProductId, product_id]);

    useEffect(() => {
        setMockup({
            ...mockup,
            [line_item_id]: {
                ...mockup?.[line_item_id],
                "Front": mockup_front || "",
                "Back": mockup_back || "",
                "Neck": mockup_neck || "",
                "Right": mockup_right || "",
                "Left": mockup_left || "",
                "AOP_3D": mockup_aop_3d || "",
            }
        });
    }, [mockup_front, mockup_back, mockup_neck, mockup_right, mockup_left, mockup_aop_3d]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDesign({
            ...design,
            [line_item_id]: {
                ...design?.[line_item_id],
                "Front": design_front || "",
                "Back": design_back || "",
                "Neck": design_neck || "",
                "Right": design_right || "",
                "Left": design_left || "",
                "AOP_3D": design_aop_3d || "",
            }
        });
    }, [design_front, design_back, design_neck, design_right, design_left, design_aop_3d]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClickSaveEditOrder = () => {
        saveEditOrder({ _id, design_fee, mockup, design, page: props.editdesign.page, sizePerPage: props.editdesign.sizePerPage, searchText: props.editdesign.searchText, filter_printer: props.editdesign.filter_printer, filter_shop_code: props.editdesign.filter_shop_code, filter_status: props.editdesign.filter_status, filter_work_status: props.editdesign.filter_work_status, filter_staff: props.editdesign.filter_staff }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "mockup_front") {
            setMockupFront(e.target.value);
        }
        if (e.target.name === "mockup_back") {
            setMockupBack(e.target.value);
        }
        if (e.target.name === "mockup_neck") {
            setMockupNeck(e.target.value);
        }
        if (e.target.name === "mockup_right") {
            setMockupRight(e.target.value);
        }
        if (e.target.name === "mockup_left") {
            setMockupLeft(e.target.value);
        }
        if (e.target.name === "mockup_aop_3d") {
            setMockupAop3d(e.target.value);
        }
        if (e.target.name === "design_fee") {
            setDesignFee({
                ...design_fee,
                [line_item_id]: e.target.value
            });
        }
        if (e.target.name === "design_front") {
            setDesignFront(e.target.value);
        }
        if (e.target.name === "design_back") {
            setDesignBack(e.target.value);
        }
        if (e.target.name === "design_neck") {
            setDesignNeck(e.target.value);
        }
        if (e.target.name === "design_right") {
            setDesignRight(e.target.value);
        }
        if (e.target.name === "design_left") {
            setDesignLeft(e.target.value);
        }
        if (e.target.name === "design_aop_3d") {
            setDesignAop3d(e.target.value);
        }
    }

    const onClickMockup = (type, mockup_type) => {
        if (type === "confirm") {
            if (mockup_type === "Front") {
                setMockupFront(mockup_front);
            }
            if (mockup_type === "Back") {
                setMockupBack(mockup_back);
            }
            if (mockup_type === "Neck") {
                setMockupNeck(mockup_neck);
            }
            if (mockup_type === "Right") {
                setMockupRight(mockup_right);
            }
            if (mockup_type === "Left") {
                setMockupLeft(mockup_left);
            }
            if (mockup_type === "AOP_3D") {
                setMockupAop3d(mockup_aop_3d);
            }
        }
        if (mockup_type === "Front") {
            setIsEditMockupFront(!isEditMockupFront);
        }
        if (mockup_type === "Back") {
            setIsEditMockupBack(!isEditMockupBack);
        }
        if (mockup_type === "Neck") {
            setIsEditMockupNeck(!isEditMockupNeck);
        }
        if (mockup_type === "Right") {
            setIsEditMockupRight(!isEditMockupRight);
        }
        if (mockup_type === "Left") {
            setIsEditMockupLeft(!isEditMockupLeft);
        }
        if (mockup_type === "AOP_3D") {
            setIsEditMockupAop3d(!isEditMockupAop3d);
        }
    }

    const onClickDesign = (type, design_type) => {
        if (type === "confirm") {
            if (design_type === "Front") {
                setDesignFront(design_front);
            }
            if (design_type === "Back") {
                setDesignBack(design_back);
            }
            if (design_type === "Neck") {
                setDesignNeck(design_neck);
            }
            if (design_type === "Right") {
                setDesignRight(design_right);
            }
            if (design_type === "Left") {
                setDesignLeft(design_left);
            }
            if (design_type === "AOP_3D") {
                setDesignAop3d(design_aop_3d);
            }
        }
        if (design_type === "Front") {
            setIsEditDesignFront(!isEditDesignFront);
        }
        if (design_type === "Back") {
            setIsEditDesignBack(!isEditDesignBack);
        }
        if (design_type === "Neck") {
            setIsEditDesignNeck(!isEditDesignNeck);
        }
        if (design_type === "Right") {
            setIsEditDesignRight(!isEditDesignRight);
        }
        if (design_type === "Left") {
            setIsEditDesignLeft(!isEditDesignLeft);
        }
        if (design_type === "AOP_3D") {
            setIsEditDesignAop3d(!isEditDesignAop3d);
        }
    }

    const onChangeDesignMatch = (e) => {
        setMockupFront(e?.mockup?.Front || "");
        setMockupBack(e?.mockup?.Back || "");
        setMockupNeck(e?.mockup?.Neck || "");
        setMockupRight(e?.mockup?.Right || "");
        setMockupLeft(e?.mockup?.Left || "");
        setMockupAop3d(e?.mockup?.AOP_3D || "");

        setDesignFront(e?.design?.Front || "");
        setDesignBack(e?.design?.Back || "");
        setDesignNeck(e?.design?.Neck || "");
        setDesignRight(e?.design?.Right || "");
        setDesignLeft(e?.design?.Left || "");
        setDesignAop3d(e?.design?.AOP_3D || "");

        setDesignMatch(e?.value || "");
    }

    const onOpenModal = () => {
        openModal();
    };

    const onKeyPress = (e) => {
        if ((/^[0-9.]+$/).test(e)) {
            e.preventDefault();
        }
    }

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalEditDesign}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit Design")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Title")}</span>
                        </label>
                        <input type="text" className="form-control" value={product_name} placeholder="" name="product_name" disabled />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Main Images")}</span>
                        </label>
                        <div className="d-flex justify-content-sm-evenly mb-5">
                            {
                                main_images.map((v) => {
                                    return (
                                        <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                            <div className="symbol symbol-50px me-2">
                                                <Link to={v} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: v }, largeImage: { src: v, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="">{t("Design fee")}</span>
                        </label>
                        <div className="input-group">
                            <input type="number" className="form-control" value={design_fee?.[line_item_id]} placeholder="" name="design_fee" onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                            <span className="input-group-text">$</span>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Design match")}</span>
                        </label>
                        <Select
                            isClearable
                            name="design_match"
                            options={list_design}
                            className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
                            classNamePrefix="select"
                            value={list_design.filter(({ value }) => design_match === value)}
                            onChange={(e) => { onChangeDesignMatch(e) }}
                            placeholder={t("Select a design ... ")}
                            formatOptionLabel={(v) => (
                                <div className="option cursor-pointer">
                                    <span className="">{v.label}</span>
                                    <div className="row">
                                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                <span className="">{t("Mockup")}</span>
                                            </label>
                                            <div className="d-flex justify-content-sm-evenly mb-5">
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Front</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.mockup?.Front ?
                                                                <Link to={v.mockup?.Front} target="_blank" >
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Front) }, largeImage: { src: formatterImage(v?.mockup?.Front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Back</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.mockup?.Back ?
                                                                <Link to={v.mockup?.Back} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Back) }, largeImage: { src: formatterImage(v?.mockup?.Back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Neck</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.mockup?.Neck ?
                                                                <Link to={v.mockup?.Neck} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Neck) }, largeImage: { src: formatterImage(v?.mockup?.Neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-sm-evenly mb-5">
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Right</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.mockup?.Right ?
                                                                <Link to={v.mockup?.Right} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Right) }, largeImage: { src: formatterImage(v?.mockup?.Right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Left</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.mockup?.Left ?
                                                                <Link to={v.mockup?.Left} target="_blank" >
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Left) }, largeImage: { src: formatterImage(v?.mockup?.Left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">AOP_3D</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.mockup?.AOP_3D ?
                                                                <Link to={v.mockup?.AOP_3D} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.AOP_3D) }, largeImage: { src: formatterImage(v?.mockup?.AOP_3D), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                <span className="">{t("Design")}</span>
                                            </label>
                                            <div className="d-flex justify-content-sm-evenly mb-5">
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Front</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.design?.Front ?
                                                                <Link to={v.design?.Front} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Front) }, largeImage: { src: formatterImage(v?.design?.Front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Back</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.design?.Back ?
                                                                <Link to={v.design?.Back} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Back) }, largeImage: { src: formatterImage(v?.design?.Back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Neck</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.design?.Neck ?
                                                                <Link to={v.design?.Neck} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Neck) }, largeImage: { src: formatterImage(v?.design?.Neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-sm-evenly mb-5">
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Right</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.design?.Right ?
                                                                <Link to={v.design?.Right} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Right) }, largeImage: { src: formatterImage(v?.design?.Right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">Left</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.design?.Left ?
                                                                <Link to={v.design?.Left} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Left) }, largeImage: { src: formatterImage(v?.design?.Left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                    <span className="h3">AOP_3D</span>
                                                    <div className="symbol symbol-50px me-2">
                                                        {
                                                            v.design?.AOP_3D ?
                                                                <Link to={v?.design?.AOP_3D} target="_blank">
                                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.AOP_3D) }, largeImage: { src: formatterImage(v?.design?.AOP_3D), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                                </Link>
                                                                :
                                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <div className="row">
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Mockup")}</span>
                            </label>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                    <span className="h3">Front</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupFront ?
                                                mockup?.[line_item_id]?.Front ?
                                                    <div onClick={() => { onClickMockup("change", "Front") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Front) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Front), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Front") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_front} placeholder="" name="mockup_front" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Front") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column ml-5">
                                    <span className="h3">Back</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupBack ?
                                                mockup?.[line_item_id]?.Back ?
                                                    <div onClick={() => { onClickMockup("change", "Back") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Back) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Back), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Back") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_back} placeholder="" name="mockup_back" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Back") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Neck</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupNeck ?
                                                mockup?.[line_item_id]?.Neck ?
                                                    <div onClick={() => { onClickMockup("change", "Neck") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Neck) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Neck), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Neck") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_neck} placeholder="" name="mockup_neck" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Neck") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Right</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupRight ?
                                                mockup?.[line_item_id]?.Right ?
                                                    <div onClick={() => { onClickMockup("change", "Right") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Right) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Right), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Right") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_right} placeholder="" name="mockup_right" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Right") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Left</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupLeft ?
                                                mockup?.[line_item_id]?.Left ?
                                                    <div onClick={() => { onClickMockup("change", "Left") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Left) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Left), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Left") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_left} placeholder="" name="mockup_left" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Left") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">AOP 3D</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupAop3d ?
                                                mockup?.[line_item_id]?.AOP_3D ?
                                                    <div onClick={() => { onClickMockup("change", "AOP_3D") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.AOP_3D) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.AOP_3D), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "AOP_3D") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_aop_3d} placeholder="" name="mockup_aop_3d" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "AOP_3D") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Design")}</span>
                            </label>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                    <span className="h3">Front</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignFront ?
                                                design?.[line_item_id]?.Front ?
                                                    <div onClick={() => { onClickDesign("change", "Front") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Front) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Front), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Front") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_front} placeholder="" name="design_front" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Front") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column ml-5">
                                    <span className="h3">Back</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignBack ?
                                                design?.[line_item_id]?.Back ?
                                                    <div onClick={() => { onClickDesign("change", "Back") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Back) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Back), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Back") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_back} placeholder="" name="design_back" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Back") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Neck</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignNeck ?
                                                design?.[line_item_id]?.Neck ?
                                                    <div onClick={() => { onClickDesign("change", "Neck") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Neck) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Neck), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Neck") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_neck} placeholder="" name="design_neck" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Neck") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Right</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignRight ?
                                                design?.[line_item_id]?.Right ?
                                                    <div onClick={() => { onClickDesign("change", "Right") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Right) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Right), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Right") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_right} placeholder="" name="design_right" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Right") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Left</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignLeft ?
                                                design?.[line_item_id]?.Left ?
                                                    <div onClick={() => { onClickDesign("change", "Left") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Left) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Left), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Left") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_left} placeholder="" name="design_left" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Left") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">AOP 3D</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignAop3d ?
                                                design?.[line_item_id]?.AOP_3D ?
                                                    <div onClick={() => { onClickDesign("change", "AOP_3D") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.AOP_3D) }, largeImage: { src: formatterImage(design?.[line_item_id]?.AOP_3D), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "AOP_3D") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_aop_3d} placeholder="" name="design_aop_3d" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "AOP_3D") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEditOrder() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaleditdesign.propTypes = {
    getDesigns: PropTypes.func.isRequired,
    saveEditOrder: PropTypes.func.isRequired,
    getProductTikTokMainImageByApiProductId: PropTypes.func.isRequired,
    formatterImage: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    order: state.order,
    design: state.design
});
const mapDispatchToProps = {
    getDesigns,
    saveEditOrder,
    getProductTikTokMainImageByApiProductId,
    formatterImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaleditdesign);