const initialState = {
	isLoading: false,
};

function aiReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_AI':
			return {
				...state,
				isLoading: action.payload
			};
		default:
		  return state;
    }
}

export default aiReducer;
