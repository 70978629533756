import { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getTikTokTopSales, uploadTemplateByLink } from "actions/spytoolActions";
import { ReactTable } from "components/Table";
import { ListDateType } from "helper/constant";
import { getProductTemplate } from "actions/productTemplateAction";
import {
    formatterCreateAt,
    convertTZ,
    sleep,
} from "components/Function";
import Loading from "components/Loading";
import _ from "lodash";
import Swal from "sweetalert2";
// import ModalImport from "./modalImport";
import { CSVLink } from "react-csv";
import SelectBox from "components/elements/selectBox";

const TopSales = ({
    getTikTokTopSales,
    product_rising_rank,
    formatterCreateAt,
    productTemplate,
    getProductTemplate,
    uploadTemplateByLink,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [dateType, setDateType] = useState("");
    const [products_selected, setProductsSelected] = useState([]);
    const [filter, setFilter] = useState({});
    const [dataExport, setDataExport] = useState([]);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const btnRef = useRef(null);

    const { t } = useTranslation();

    const { list, totalsize, isLoading } = product_rising_rank;

    const { list: listTemplate } = productTemplate;

    const list_template = listTemplate.map((v, i) => {
        return { "value": v._id, "label": v.title }
    })

    const list_country = [
        { "value": "US", "label": "United States" },
        { "value": "GB", "label": "United Kingdom" },
        { "value": "ID", "label": "Indonesia" },
        { "value": "TH", "label": "Thailand" },
        { "value": "PH", "label": "Philipines" },
        { "value": "MY", "label": "Malaysia" },
        { "value": "SG", "label": "Singapore" },
    ]

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "20",
            value: 20,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    useEffect(() => {
        if (Object.keys(filter).length) {
            getTikTokTopSales({ page, sizeperpage: sizePerPage, search: searchText, filter });
        }
    }, [getTikTokTopSales, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        getProductTemplate()
    }, [getProductTemplate]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(10);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "product_cover",
            text: t("Image"),
            sort: true,
            formatter: (cell, row) => (
                <>
                    <div className="symbol symbol-50px">
                        <Link to={"https://shop.tiktok.com/view/product/" + row.id || "#"} target="_blank"><img src={cell ? "https://t-img.picturehaven.net/product/" + cell : require("components/assets/media/misc/image.png")} alt="" height={200} /></Link>
                    </div>
                </>
            ),
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            formatter: (cell, row) => (
                <>
                    <h3 >
                        {cell}
                    </h3>
                </>
            ),
        },
        {
            dataField: "sku",
            text: t("Type"),
            sort: true,
            formatter: (cell, row) => cell.length > 0 ? "VARIANTS" : "PRODUCT",
        },
        {
            dataField: "sale_prop",
            text: t("Sale Prop"),
            sort: true,
            style: {
                maxWidth: "3000px",
                wordBreak: "break-all",
                whiteSpace: "pre-line",
            },
            formatter: (cell, row, rowIndex) => (
                <>
                    {
                        row?.sale_prop.map((v, i) => {
                            return (
                                <>
                                    <div>
                                        <h4>{v?.prop_name}:</h4> {v?.sale_prop_values?.map((o, k) => {
                                            return (
                                                <>
                                                    ({o?.prop_value}) -
                                                </>
                                            )
                                        })}
                                    </div>
                                    {i + 1 !== row?.sale_prop.length ? <hr /> : ""}
                                </>
                            );
                        })
                    }
                </>
            ),
        },
        {
            dataField: "sold_count",
            text: t("Yesterday Sold"),
            sort: true,
        },
        {
            dataField: "shop",
            text: t("Shop"),
            sort: true,
            formatter: (cell, row) => cell?.shop_name + " - " + cell?.shop_id
        },
        {
            dataField: "price",
            text: t("Price"),
            sort: true,
        },
        {
            dataField: "-",
            text: t("Category"),
            sort: true,
            formatter: (cell, row) => [row?.category1, row?.category2, row?.category3].join(' - ')
        },
        {
            dataField: "country",
            text: t("Country"),
            sort: true,
        },
        {
            dataField: "first_time",
            text: t("Time Created"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(10);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };


    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(10);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    const handleSubmitUpload = async (data) => {
        let data_uniqe = _.uniqBy(data, "id");
        Promise.all(data_uniqe?.map(async (obj, iv) => {
            await sleep(1000 * iv)
            return new Promise(async (resolve2, reject2) => {
                await uploadTemplateByLink(obj).then((data_) => {
                    resolve2('ok');
                })
                    .catch((err) => {
                        resolve2('ok');
                    });
                resolve2('ok');
            });
        })).then(async (dat) => {
            setProductsSelected([])
            return Swal.fire(t("Success"), t("Upload Success!"), "success");
        })
    };

    const onClickExportData = async () => {
        let save_data_export = [];
        save_data_export = list?.filter((v, i) => products_selected?.includes(v._id))?.map((v, i) => {
            return { title: v.title, image1: v?.product_cover ? "https://t-img.picturehaven.net/product/" + v?.product_cover : "", image2: v?.main_images?.[1] || "", image3: v?.main_images?.[2] || "", image4: v?.main_images?.[3] || "", image5: v?.main_images?.[4] || "", image6: v?.main_images?.[5] || "", image7: v?.main_images?.[6] || "", image8: v?.main_images?.[7] || "", image9: v?.main_images?.[8] || "" }
        })
        setDataExport(save_data_export);
        setInitiateDownload(true);
        await sleep(2500)
    }

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row._id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
        if (products_selected_tmp.length !== 0) {
            // setLoadingSync(false);
        }
        if (products_selected_tmp.length === 0) {
            // setLoadingSync(true);
        }
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v._id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
        if (products_selected_tmp.length !== 0) {
            // setLoadingSync(false);
        }
        if (products_selected_tmp.length === 0) {
            // setLoadingSync(true);
        }
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({products_selected.length})</span>
            </div>
        ),
    };

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex flex-md-row flex-column gap-2">
                    <button onClick={onClickExportData} className="btn btn-sm btn-success mr-1"><i className="ki-outline ki-exit-down"></i>Export</button>
                    <CSVLink filename={"data_amazon_" + dataExport.length + "_products.csv"} data={dataExport}>
                        <span ref={btnRef} />
                    </CSVLink>
                    <SelectBox
                        options={list_country}
                        name="filter_staff"
                        value={filter.country || ""}
                        onChange={(data) =>
                            setFilter((prev) => ({
                                ...prev,
                                country: data.value || "",
                            }))
                        }
                    />
                    <Select
                        name="dateType"
                        options={ListDateType}
                        className="al-select"
                        classNamePrefix="select"
                        value={ListDateType.filter(({ value }) => value === dateType)}
                        onChange={(e) => {
                            setDateType(e?.value || "");
                        }}
                        placeholder="Select a date ... "
                    />
                    {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                        <DateRangePicker
                            onCallback={handleEventDate}
                            initialSettings={{
                                startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                showDropdowns: true,
                                locale: { format: "DD/MM/YYYY" },
                            }}
                        >
                            <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                        </DateRangePicker>
                    ) : (
                        ""
                    )}
                    {/* <ModalImport
                        data={products_selected}
                        allData={list}
                        template={list_template}
                        onSubmit={(data) => {
                            handleSubmitUpload(data)
                        }}
                    /> */}
                </div>
            </>
        );
    };

    return (
        <>
            <div className="card min-w-full">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable
                            columns={columns}
                            data={list || []}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit al-d-none-option-react-select"
                            sizePerPageList={sizePerPageList}
                            isSelectRow={true}
                            selectRow={selectRow}
                        />
                    </div>
                </div>
            </div>
        </>

    );
};

const mapStateToProps = ({ product_rising_rank, productTemplate }) => ({ product_rising_rank, productTemplate });
const mapDispatchToProps = {
    formatterCreateAt,
    getTikTokTopSales,
    getProductTemplate,
    uploadTemplateByLink
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSales);