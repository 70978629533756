import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";
import { arrayHierarchy } from "components/Function";

const getCategories = (callback = null) => (dispatch, getState) => {
    return axios
        .get(`/api/categories`, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                if (callback) callback?.(res?.data.data?.categories || []);
                return arrayHierarchy(res?.data.data?.categories || []);
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const getAttributes = (id) => (dispatch, getState) => {
    return axios
        .get(`/api/categories/attributes/${id}`, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                return res.data.data;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

export { getCategories, getAttributes };
