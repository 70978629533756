import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";
import _ from 'lodash';

const getProducts =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_PRODUCT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/product?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PRODUCT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_PRODUCT",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PRODUCT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getProductById = (id) => (dispatch, getState) => {
    return axios
        .get(`/api/product/${id}`, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                return res.data.data;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const addProduct = (data) => async (dispatch, getState) => {
    const check = await Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    });

    if (check?.value) {
        data.setLoading(true);
        return axios
            .post("/api/product", data, tokenConfig(getState))
            .then((res) => {
                data.setLoading(false);
                if (res.data.status === 200) {
                    Swal.fire(t("Success"), t(res.data.message), "success")
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            })
            .catch((err) => {
                data.setLoading(false);
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    }
};

const updateProduct = (data, setLoading) => async (dispatch, getState) => {
    const check = await Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to update product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    });

    if (check.value) {
        setLoading(true);
        return axios
            .put("/api/product", data, tokenConfig(getState))
            .then((res) => {
                setLoading(false);
                if (res.data.status === 200) {
                    Swal.fire(t("Success"), t(res.data.message), "success");
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    }
};

const deleteUpdateCategory = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to update category product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            return axios
                .post("/api/product/update-categories", data, tokenConfig(getState))
                .then((res) => {
                    if (res.data.status === 200) {
                        Swal.fire(t("Success"), t(res.data.message), "success");
                        return res.data.data;
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                        return [];
                    }
                })
                .catch((err) => {
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                    return [];
                });
        }
    });
};

const duplicateProduct = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then(async (result) => {
        if (result.value) {
            return Promise.all(
                data?.map((id) => {
                    return new Promise(async (resolve2, reject2) => {
                        axios
                            .post(`/api/product/duplicate`, { id }, tokenConfig(getState))
                            .then((res) => {
                                if (res.data.status === 200) {
                                    resolve2(res.data.message)

                                } else {
                                    resolve2(res.data.message)
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                resolve2(err)
                            });
                    });
                })).then(async (a) => {
                    Swal.fire(t("Success"), t("Delete Success"), "success");
                    return true;
                })
        }
    });
};

const deleteProduct = (id, setLoading) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then(async (result) => {
        if (result.value) {
            setLoading(true);
            if (Array.isArray(id)) {
                await Promise.all(
                    id?.map((i) => {
                        return new Promise(async (resolve2, reject2) => {
                            axios
                                .delete(`/api/product/${i}`, tokenConfig(getState))
                                .then((res) => {
                                    if (res.data.status === 200) {
                                        resolve2(res.data.message)

                                    } else {
                                        resolve2(res.data.message)
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                    resolve2(err)
                                });
                        });
                    })).then(async (a) => {
                        Swal.fire(t("Success"), t("Delete Success"), "success");
                        return true;
                    })
                setLoading(false);
            } else {
                return axios
                    .delete(`/api/product/${id}`, tokenConfig(getState))
                    .then((res) => {
                        setLoading(false);
                        if (res.data.status === 200) {
                            Swal.fire(t("Success"), t(res.data.message), "success");
                        } else {
                            Swal.fire(t("Error"), t(res.data.message), "error");
                        }
                        return true;
                    })
                    .catch((err) => {
                        setLoading(false);
                        Swal.fire(t("Error"), t("An error occurred!"), "error");
                        return false;
                    });
            }
        }
    });
};

const uploadProductToTiktok = (productId, shopId) => (dispatch, getState) => {
    return axios
        .post(`/api/product/tiktok`, { productId, shopId }, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                Swal.fire(t("Success"), t(res.data.message), "success");
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const uploadProductToTiktokTool = (productId, shopId, saveMode, timeDelaySaveMode) => (dispatch, getState) => {
    return axios
        .post(`/api/product/tiktok`, { productId, shopId, saveMode, timeDelaySaveMode }, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const uploadProductToTiktokToolv2 = (products, shops) => (dispatch, getState) => {
    return axios
        .post(`/api/product/tiktokv2`, { products, shops }, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const getProductsByShops =
    ({ page = 0, sizeperpage = 0, search = "", filter = {}, promotion_selected = "", shops_selected = [] } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_PROMOTION", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`;
            var param_promotion_selected = "";
            if (promotion_selected !== "") {
                param_promotion_selected = `&promotion_selected=${promotion_selected}`;
            }
            var param_shops_selected = "";
            if (shops_selected.length > 0) {
                param_shops_selected = `&shops_selected=${shops_selected.join(",")}`;
            }
            axios
                .get(
                    `/api/product/list-products-by-shops?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}${param_promotion_selected}${param_shops_selected}`,
                    tokenConfig(getState)
                )
                .then((res) => {
                    dispatch({ type: "LOADING_PROMOTION", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "LIST_PRODUCTS_BY_SHOPS",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PROMOTION", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const syncProduct = (shops_selecteds = [], type = "") => (dispatch, getState) => {
    const chunk_shop = _.chunk(shops_selecteds, 1);
    const data_success = [];
    const data_err = [];
    return Promise.all(
        chunk_shop?.map((shops_selected) => {
            return new Promise(async (resolve2, reject2) => {
                var body = {
                    shops_selected,
                    type
                }
                await axios
                    .post(`/api/product/sync`, body, tokenConfig(getState))
                    .then((res) => {
                        if (res.data.status === 200) {
                            data_success.push(res.data.message)
                            resolve2(res.data.message)
                        } else {
                            data_err.push(res.data.message)
                            resolve2(res.data.message)
                        }
                    })
                    .catch((err) => {
                        data_err.push(err)
                        resolve2(err)
                    });

            });
        })).then(async (a) => {
            Swal.fire(t("Success"), t(`Sync product successfully ${data_success.length} shops/ ${data_err.length} shops failed!`), "success");
            return true;
        })
}

const shareProductById = (email, product) => (dispatch, getState) => {
    return axios
        .post(`/api/product/share`, { product, email }, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => {
            return;
        });
};

const createProductsByTemplate = (data) => async (dispatch, getState) => {
    const check = await Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to create list product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    });

    if (check.value) {
        return axios
            .post("/api/product/create-by-template", data, tokenConfig(getState))
            .then((res) => {
                if (res.data.status === 200) {
                    Swal.fire(t("Success"), t(res.data.message), "success");
                    return true;
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    } else {
        return false;
    }
};

const changeProductByTemplate = (data) => (dispatch, getState) => {
    return axios
        .post(`/api/product/change-by-template`, data, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => {
            return;
        });
};

const createProductById = (id) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to create product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then(async (result) => {
        if (result.value) {
            if (Array.isArray(id)) {
                return Promise.all(
                    id?.map((i) => {
                        return new Promise(async (resolve2, reject2) => {
                            axios
                                .post(`/api/product/create-by-id`, { id: i }, tokenConfig(getState))
                                .then((res) => {
                                    if (res.data.status === 200) {
                                        resolve2(res.data.message)

                                    } else {
                                        resolve2(res.data.message)
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                    resolve2(err)
                                });
                        });
                    })).then(async (a) => {
                        Swal.fire(t("Success"), t("Delete Success"), "success");
                        return true;
                    })
            } else {
                return axios
                    .post(`/api/product/create-by-id`, { id }, tokenConfig(getState))
                    .then((res) => {
                        if (res.data.status === 200) {
                            Swal.fire(t("Success"), t(res.data.message), "success");
                        } else {
                            Swal.fire(t("Error"), t(res.data.message), "error");
                        }
                        return true;
                    })
                    .catch((err) => {
                        console.log(err);
                        Swal.fire(t("Error"), t("An error occurred!"), "error");
                        return false;
                    });
            }
        }
    });
};

export {
    getProducts,
    addProduct,
    deleteProduct,
    getProductById,
    updateProduct,
    uploadProductToTiktok,
    getProductsByShops,
    uploadProductToTiktokTool,
    syncProduct,
    uploadProductToTiktokToolv2,
    deleteUpdateCategory,
    shareProductById,
    duplicateProduct,
    changeProductByTemplate,
    createProductsByTemplate,
    createProductById,
};
