import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";


const getListApps = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
    dispatch({ type: 'LOADING_APP', payload: true });
    var param_search = "";
    if (search !== "") {
        param_search = `&search=${search}`;
    }
    axios.get(`/api/app/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
        dispatch({ type: 'LOADING_APP', payload: false });

        if (res.data.status === 200) {
            dispatch({
                type: 'ADMIN_LIST_APP',
                payload: res.data.data
            });
        } else {
            Swal.fire(t("Error"), t(res.data.message), 'error');
        }
    }).catch(err => {
        dispatch({ type: 'LOADING_APP', payload: false });
        Swal.fire(t("Error"), t("An error occurred!"), 'error');
    });
};

const deleteApp = (data) => (dispatch, getState) => {
    var body = {
        _id: data._id
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete app?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_APP', payload: true });
            axios.post('/api/app/delete', body, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_APP', payload: false });
                if (res.data.status === 200) {
                    dispatch(getListApps(data.page || 1, data.sizePerPage || 10, data.searchText));
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_APP', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};

const addApp = (data, closemodal) => (dispatch, getState) => {
    var body = {
        name: data.name,
        app_id: data.app_id,
        app_key: data.app_key,
        app_secret: data.app_secret,
        redirect_url: data.redirect_url,
        webhook_url: data.webhook_url,
        app_url: data.app_url,
        authorization_link: data.authorization_link,
        target: data.target,
        app_type: data.app_type,
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add app?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel")
    }).then((result) => {
        if (result.value) {
            dispatch({ type: 'LOADING_APP', payload: true });
            axios.post('/api/app/add', body, tokenConfig(getState)).then(res => {
                dispatch({ type: 'LOADING_APP', payload: false });
                if (res.data.status === 200) {
                    closemodal();
                    dispatch(getListApps(1, 10));
                    Swal.fire(t("Success"), t(res.data.message), 'success');
                } else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
                }
            }).catch(err => {
                dispatch({ type: 'LOADING_APP', payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
            });
        }
    })
};

const updateApp =
    (data) =>
        (dispatch, getState) => {
            const body = {
                ...data,
            };
            Swal.fire({
                title: t("Are you sure?"),
                text: t("Are you sure to edit app?"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("Sure"),
                cancelButtonText: t("Cancel")
            }).then((result) => {
                if (result.value) {
                    dispatch({ type: 'LOADING_APP', payload: true });
                    axios
                        .post("/api/app/update", body, tokenConfig(getState))
                        .then((res) => {
                            dispatch({ type: "LOADING_APP", payload: false });
                            if (res.data.status === 200) {
                                dispatch(getListApps(1, 10));
                                Swal.fire("Success", res.data.message, "success");
                            } else {
                                Swal.fire("Error", res.data.message, "error");
                            }
                        })
                        .catch((err) => {
                            dispatch({ type: "LOADING_APP", payload: false });
                            Swal.fire("Error", "An error occurred!", "error");
                        });
                }
            })
        };

const getAppRandomByRegion = (region, type) => (dispatch, getState) => {
    return axios.get(`/api/app/get-app-authorize?region=${region}&app_type=${type}`, tokenConfig(getState)).then(res => {
        return res.data;
    }).catch(err => {
        Swal.fire(t("Error"), t("An error occurred!"), 'error');
    });
};

export {
    getListApps,
    deleteApp,
    addApp,
    updateApp,
    getAppRandomByRegion,
};
