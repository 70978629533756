import axios from "axios";
import Swal from "sweetalert2";
import { t } from "i18next";
import { tokenConfig } from "./websiteActions";

const shopAuthorize = (code, app) => (dispatch, getState) => {
    return axios.post("/api/tiktok/shop-message/authorize-code", { code, app }, tokenConfig(getState));
};

const getShops =
    ({ page = 1, sizeperpage = 10, search = "", filter = {}, sort_by = "", sort_order = "" }) =>
    (dispatch, getState) => {
        dispatch({ type: "LOADING_SHOP_MESSAGE_MESSAGE", payload: true });
        var param_search = "";
        if (search !== "") {
            param_search = `&search=${search}`;
        }
        filter = new URLSearchParams(filter).toString();
        if (filter) filter = `&${filter}`;

        var param_sort_by = "";
        if (sort_by !== "") {
            param_sort_by = `&sort_by=${sort_by}`;
        }

        var param_sort_order = "";
        if (sort_order !== "") {
            param_sort_order = `&sort_order=${sort_order}`;
        }

        return axios
            .get(
                `/api/shop-message?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}${param_sort_by}${param_sort_order}`,
                tokenConfig(getState)
            )
            .then((res) => {
                dispatch({ type: "LOADING_SHOP_MESSAGE_MESSAGE", payload: false });

                if (res.data.status === 200) {
                    dispatch({
                        type: "ADMIN_LIST_SHOP_MESSAGE",
                        payload: res.data.data,
                    });
                    return res.data.data;
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                    return [];
                }
            })
            .catch((err) => {
                dispatch({ type: "LOADING_SHOP_MESSAGE_MESSAGE", payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), "error");
                return [];
            });
    };

const renewalShop = (data) => (dispatch, getState) => {
    const body = {
        ...data,
    };

    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to renewal shop?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            return axios
                .post("/api/shop-message/renewal", body, tokenConfig(getState))
                .then((res) => {
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

const updateShop = (data) => (dispatch, getState) => {
    const body = {
        ...data,
    };
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to edit shop?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            return axios
                .post("/api/shop-message/update", body, tokenConfig(getState))
                .then((res) => {
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

const deleteShop =
    ({ id, page, sizePerPage, searchText, filter, sort_by, sort_order }) =>
    (dispatch, getState) => {
        Swal.fire({
            title: t("Are you sure?"),
            text: t("Are you sure to delete shop?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sure"),
            cancelButtonText: t("Cancel"),
        }).then((result) => {
            if (result.value) {
                dispatch({ type: "LOADING_SHOP_MESSAGE", payload: true });
                axios
                    .post(
                        "/api/shop-message/delete",
                        {
                            id,
                        },
                        tokenConfig(getState)
                    )
                    .then((res) => {
                        dispatch({ type: "LOADING_SHOP_MESSAGE", payload: false });
                        if (res.data.status === 200) {
                            dispatch(
                                getShops({
                                    page,
                                    sizeperpage: sizePerPage,
                                    search: searchText,
                                    filter,
                                    sort_by,
                                    sort_order,
                                })
                            );
                            Swal.fire(t("Success"), t(res.data.message), "success");
                        } else {
                            Swal.fire(t("Error"), t(res.data.message), "error");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch({ type: "LOADING_SHOP_MESSAGE", payload: false });
                        Swal.fire(t("Error"), t("An error occurred!"), "error");
                    });
            }
        });
    };

const cancelDeleteShop =
    ({ id, page, sizePerPage, searchText, filter, sort_by, sort_order }) =>
    (dispatch, getState) => {
        Swal.fire({
            title: t("Are you sure?"),
            text: t("Are you sure to cancel delete shop?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sure"),
            cancelButtonText: t("Cancel"),
        }).then((result) => {
            if (result.value) {
                dispatch({ type: "LOADING_SHOP_MESSAGE", payload: true });
                axios
                    .post(
                        "/api/shop-message/cancel-delete",
                        {
                            id,
                        },
                        tokenConfig(getState)
                    )
                    .then((res) => {
                        dispatch({ type: "LOADING_SHOP_MESSAGE", payload: false });
                        if (res.data.status === 200) {
                            dispatch(
                                getShops({
                                    page,
                                    sizeperpage: sizePerPage,
                                    search: searchText,
                                    filter,
                                    sort_by,
                                    sort_order,
                                })
                            );
                            Swal.fire(t("Success"), t(res.data.message), "success");
                        } else {
                            Swal.fire(t("Error"), t(res.data.message), "error");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch({ type: "LOADING_SHOP_MESSAGE", payload: false });
                        Swal.fire(t("Error"), t("An error occurred!"), "error");
                    });
            }
        });
    };

export { getShops, shopAuthorize, renewalShop, updateShop, cancelDeleteShop, deleteShop };
