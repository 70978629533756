import axios from "axios";
import Swal from "sweetalert2";
import { t } from "i18next";
import { tokenConfig } from "./websiteActions";

const getRecharge =
    ({ page = 1, sizeperpage = 10, search = "", filter = {} }) =>
    (dispatch, getState) => {
        var param_search = "";
        if (search !== "") {
            param_search = `&search=${search}`;
        }
        filter = new URLSearchParams(filter).toString();
        if (filter) filter = `&${filter}`;

        dispatch({ type: "LOADING_ADMIN_RECHARGE", payload: true });
        return axios
            .get(
                `/api/user-pay-transition?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`,
                tokenConfig(getState)
            )
            .then((res) => {
                dispatch({ type: "LOADING_ADMIN_RECHARGE", payload: false });

                if (res.data.status === 200) {
                    dispatch({
                        type: "ADMIN_RECHARGE_USER",
                        payload: res.data.data,
                    });
                    return res.data.data;
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                    return [];
                }
            })
            .catch((err) => {
                dispatch({ type: "LOADING_ADMIN_RECHARGE", payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), "error");
                return [];
            });
    };

const addRecharge = (data) => async (dispatch, getState) => {
    const check = await Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to create recharge?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    });
    if (check?.value) {
        return await axios
            .post("/api/user-pay-transition", data, tokenConfig(getState))
            .then((res) => {
                if (res.data.status === 200) {
                    Swal.fire(t("Success"), t(res?.data?.message || "Create Success!"), "success");
                    return true;
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    }
};

const deleteRecharge =
    ({ id }) =>
    (dispatch, getState) => {
        return Swal.fire({
            title: t("Are you sure?"),
            text: t("Are you sure to delete?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sure"),
            cancelButtonText: t("Cancel"),
        }).then(async (result) => {
            if (result.value) {
                return axios
                    .delete(`/api/user-pay-transition/${id}`, tokenConfig(getState))
                    .then((res) => {
                        if (res.data.status === 200) {
                            Swal.fire(t("Success"), t(res.data.message), "success");
                            return true;
                        } else {
                            Swal.fire(t("Error"), t(res.data.message), "error");
                        }
                    })
                    .catch((err) => {
                        dispatch({ type: "LOADING_APP", payload: false });
                        Swal.fire(t("Error"), t("An error occurred!"), "error");
                    });
            }
        });
    };


export { getRecharge, addRecharge, deleteRecharge };
