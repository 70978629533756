import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Packages from "./packages";
import CountUp from "react-countup";

function Main(props) {
    // const { t } = useTranslation();
    var { pathname } = useLocation();

    return (
        <Fragment>
            <div
                id="kt_body"
                data-bs-spy="scroll"
                data-bs-target="#kt_landing_menu"
                className="bg-body position-relative"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="mb-0" id="home">
                        <div
                            className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                            style={{
                                backgroundImage: `url(${
                                    require("components/assets/media/svg/illustrations/landing.svg").default
                                })`,
                            }}
                        >
                            <div
                                className="landing-header"
                                data-kt-sticky="true"
                                data-kt-sticky-name="landing-header"
                                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                            >
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center flex-equal">
                                            <button
                                                className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                                id="kt_landing_menu_toggle"
                                            >
                                                <i className="ki-outline ki-abstract-14 fs-2hx"></i>
                                            </button>
                                            <Link to="/">
                                                <img
                                                    alt="Logo"
                                                    src={require("components/assets/media/custom/logo.png")}
                                                    className="logo-default h-60px h-lg-60px"
                                                />
                                                <img
                                                    alt="Logo"
                                                    src={require("components/assets/media/custom/logo.png")}
                                                    className="logo-sticky h-20px h-lg-25px"
                                                />
                                            </Link>
                                        </div>
                                        <div className="d-lg-block" id="kt_header_nav_wrapper">
                                            <div
                                                className="d-lg-block p-5 p-lg-0"
                                                data-kt-drawer="true"
                                                data-kt-drawer-name="landing-menu"
                                                data-kt-drawer-activate="{default: true, lg: false}"
                                                data-kt-drawer-overlay="true"
                                                data-kt-drawer-width="200px"
                                                data-kt-drawer-direction="start"
                                                data-kt-drawer-toggle="#kt_landing_menu_toggle"
                                                data-kt-swapper="true"
                                                data-kt-swapper-mode="prepend"
                                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                                            >
                                                <div
                                                    className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-600 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                                                    id="kt_landing_menu"
                                                >
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link active py-3 px-4 px-xxl-6"
                                                            to="#"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Home
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            to="#"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            How it Works
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            to="#"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Achievements
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            to="/privacy-policy"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Privacy Policy
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            to="#"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Pricing
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            to="#"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            About Us
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-equal text-end ms-1">
                                            <Link
                                                to={
                                                    pathname.indexOf("/extension") > -1
                                                        ? "/admin/extension-etsy"
                                                        : "/admin"
                                                }
                                                className="btn btn-success"
                                            >
                                                Sign In
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                                <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                                    <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-15">
                                        Build An Tiktok Shop Solutions <br />
                                        with{" "}
                                        <span
                                            style={{
                                                background: "linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)",
                                                WebkitBackgroundClip: "text",
                                                WebkitTextFillColor: "transparent",
                                            }}
                                        >
                                            <span id="kt_landing_hero_text">The Best Ever</span>
                                        </span>
                                    </h1>
                                    <Link to="/admin" className="btn btn-primary">
                                        Try Cotik
                                    </Link>
                                    {/*end::Action*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Clients*/}
                                {/*end::Clients*/}
                            </div>
                        </div>
                        <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <div className="mb-n10 mb-lg-n20 z-index-2">
                        <div className="container">
                            <div className="text-center mb-17">
                                <h3
                                    className="fs-2hx text-gray-900 mb-5"
                                    id="how-it-works"
                                    data-kt-scroll-offset="{default: 100, lg: 150}"
                                >
                                    How it Works
                                </h3>
                                <div className="fs-5 text-muted fw-bold">
                                    Save thousands to millions of bucks by using single tool <br />
                                    for different amazing and great useful admin
                                </div>
                            </div>
                            <div className="row w-100 gy-10 mb-md-20">
                                <div className="col-md-4 px-5">
                                    <div className="text-center mb-10 mb-md-0">
                                        <img
                                            src={require("components/assets/media/illustrations/unitedpalms-1/2.png")}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        <div className="d-flex flex-center mb-5">
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                1
                                            </span>
                                            <div className="fs-5 fs-lg-3 fw-bold text-gray-900">Jane Miller </div>
                                        </div>
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Save thousands to millions of bucks <br />
                                            by using single tool for different <br />
                                            amazing and great
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 px-5">
                                    <div className="text-center mb-10 mb-md-0">
                                        <img
                                            src={require("components/assets/media/illustrations/unitedpalms-1/8.png")}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        <div className="d-flex flex-center mb-5">
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                2
                                            </span>
                                            <div className="fs-5 fs-lg-3 fw-bold text-gray-900">Setup Your App </div>
                                        </div>
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Save thousands to millions of bucks <br />
                                            by using single tool for different <br />
                                            amazing and great
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 px-5">
                                    <div className="text-center mb-10 mb-md-0">
                                        <img
                                            src={require("components/assets/media/illustrations/unitedpalms-1/12.png")}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        <div className="d-flex flex-center mb-5">
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                3
                                            </span>
                                            <div className="fs-5 fs-lg-3 fw-bold text-gray-900">Enjoy Nautica App </div>
                                        </div>
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Save thousands to millions of bucks <br />
                                            by using single tool for different <br />
                                            amazing and great
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-20">
                        <div className="landing-curve landing-dark-color ">
                            <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div className="pb-15 pt-18 landing-dark-bg">
                            <div className="container">
                                <div
                                    className="text-center mt-15 mb-18"
                                    id="achievements"
                                    data-kt-scroll-offset="{default: 100, lg: 150}"
                                >
                                    <h3 className="fs-2hx text-white fw-bold mb-5">We Make Things Better</h3>
                                    <div className="fs-5 text-gray-700 fw-bold">
                                        Save thousands to millions of bucks by using single tool <br />
                                        for different amazing and great useful admin
                                    </div>
                                </div>
                                <div className="d-flex flex-center">
                                    <div className="d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px">
                                        <div
                                            className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                            style={{
                                                backgroundImage: `url("${
                                                    require("components/assets/media/svg/misc/octagon.svg").default
                                                }")`,
                                            }}
                                        >
                                            <i className="ki-outline ki-element-11 fs-2tx text-white mb-3" />{" "}
                                            <div className="mb-0">
                                                <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                                    <div className="min-w-70px counted">
                                                        <CountUp end={15} scrollSpyOnce enableScrollSpy={true} />
                                                        k+
                                                    </div>
                                                </div>
                                                <span className="text-gray-600 fw-semibold fs-5 lh-0">
                                                    Connected Shops{" "}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                            style={{
                                                backgroundImage: `url("${
                                                    require("components/assets/media/svg/misc/octagon.svg").default
                                                }")`,
                                            }}
                                        >
                                            <i className="ki-outline ki-chart-pie-4 fs-2tx text-white mb-3" />{" "}
                                            <div className="mb-0">
                                                <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                                    <div
                                                        className="min-w-70px counted"
                                                        data-kt-countup="true"
                                                        data-kt-countup-value={80}
                                                        data-kt-countup-suffix="K+"
                                                        data-kt-initialized={1}
                                                    >
                                                        <CountUp end={80} scrollSpyOnce enableScrollSpy={true} />
                                                        K+
                                                    </div>
                                                </div>
                                                <span className="text-gray-600 fw-semibold fs-5 lh-0">
                                                    Statistic Reports{" "}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                            style={{
                                                backgroundImage: `url("${
                                                    require("components/assets/media/svg/misc/octagon.svg").default
                                                }")`,
                                            }}
                                        >
                                            <i className="ki-outline ki-basket fs-2tx text-white mb-3" />{" "}
                                            <div className="mb-0">
                                                <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                                    <div
                                                        className="min-w-70px counted"
                                                        data-kt-countup="true"
                                                        data-kt-countup-value={35}
                                                        data-kt-countup-suffix="M+"
                                                        data-kt-initialized={1}
                                                    >
                                                        <CountUp end={35} scrollSpyOnce enableScrollSpy={true} />
                                                        M+
                                                    </div>
                                                </div>
                                                <span className="text-gray-600 fw-semibold fs-5 lh-0">
                                                    Secure Payments{" "}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className=" z-index-2">
                        <div className="container">
                            <h3 className="fs-2hx text-gray-900 mb-5 text-center">About Cotik</h3>
                            <div className="fs-2 fw-semibold text-muted text-center mb-3">
                                <span className="fs-1 lh-1 text-gray-700">“</span>
                                When you care about your topic, you’ll write about it in a <br />
                                <span className="text-gray-700 me-1">more powerful</span>, emotionally expressive way
                                <span className="fs-1 lh-1 text-gray-700">“</span>
                            </div>
                            <div className="fs-2 fw-semibold text-muted text-center">
                                <span className="fs-4 fw-bold text-gray-600">Cotik Team</span>
                            </div>
                        </div>
                    </div>
                    <Packages />
                    <div className="mt-20 mb-n20 position-relative z-index-2">
                        <div className="container">
                            <div className="text-center mb-17">
                                <h3
                                    className="fs-2hx text-gray-900 mb-5"
                                    id="clients"
                                    data-kt-scroll-offset="{default: 125, lg: 150}"
                                >
                                    Features
                                </h3>
                                <div className="fs-5 text-muted fw-bold">
                                    Save thousands to millions of bucks by using single tool <br />
                                    for different amazing and great useful admin
                                </div>
                            </div>
                            <div className="row g-lg-10 mb-10 mb-lg-20">
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/3.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                Product Listing
                                            </div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Manage personal products and post to multiple stores easily.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/17.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                Promotions
                                            </div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Create discount campaigns for multiple stores in the easiest way.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/6.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                Order and Messages
                                            </div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Manage your store's orders and chat in real time with customers.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
                                style={{ background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%)" }}
                            >
                                <div className="my-2 me-5">
                                    <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">
                                        Start With Cotik App Today,{" "}
                                        <span className="fw-normal">Speed Up Development!</span>
                                    </div>
                                    <div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">
                                        Join over 100,000 Professionals Community to Stay Ahead
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer */}
                    <div className="mb-0">
                        <div className="landing-curve landing-dark-color ">
                            <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div className="landing-dark-bg pt-20">
                            <div className="container">
                                <div className="row py-10 py-lg-20">
                                    <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                                        <div className="rounded landing-dark-border p-9 mb-10">
                                            <h2 className="text-white">Would you need a Custom License?</h2>
                                            <span className="fw-normal fs-4 text-gray-700">
                                                Email us to{" "}
                                                <Link to="/support" className="text-white opacity-50 text-hover-primary">
                                                    support@cotik.app
                                                </Link>
                                            </span>
                                        </div>
                                        <div className="rounded landing-dark-border p-9">
                                            <h2 className="text-white">How About a Custom Project?</h2>
                                            <span className="fw-normal fs-4 text-gray-700">
                                                Use Our Custom Development Service.{" "}
                                                <Link to="/admin" className="text-white opacity-50 text-hover-primary">
                                                    Click to Get a Quote
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 ps-lg-16">
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex fw-semibold flex-column ms-lg-20">
                                                <h4 className="fw-bold text-gray-500 mb-6">Stay Connected</h4>
                                                <Link to="https://www.facebook.com/cotikapp" className="mb-6" target="_blank" rel="noreferrer">
                                                    <img
                                                        src={
                                                            require("components/assets/media/svg/brand-logos/facebook-4.svg")
                                                                .default
                                                        }
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Facebook
                                                    </span>
                                                </Link>
                                                <Link to="https://www.youtube.com/@cotikapp" className="mb-6" target="_blank" rel="noreferrer">
                                                    <img
                                                        src={
                                                            require("components/assets/media/svg/brand-logos/youtube-play.svg")
                                                                .default
                                                        }
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Youtube
                                                    </span>
                                                </Link>
                                                <Link to="https://t.me/cotikcommunity" className="mb-6" target="_blank" rel="noreferrer">
                                                    <img
                                                        src={
                                                            require("components/assets/media/svg/brand-logos/telegram.svg")
                                                                .default
                                                        }
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Telegram
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="landing-dark-separator"></div>
                            <div className="container">
                                <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                                    <div className="d-flex align-items-center order-2 order-md-1">
                                        <Link to="/">
                                            <img
                                                alt="Logo"
                                                src={require("components/assets/media/custom/logo.png")}
                                                className="h-15px h-md-20px"
                                            />
                                        </Link>
                                        <span className="mx-5 fs-6 fw-semibold text-gray-600 pt-1" to="/">
                                            © 2024 Cotik App.
                                        </span>
                                    </div>
                                    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                        <li className="menu-item">
                                            <Link to="/about" className="menu-link px-2">
                                                About
                                            </Link>
                                        </li>
                                        <li className="menu-item mx-5">
                                            <Link to="https://t.me/cotikcommunity" className="menu-link px-2" target="_blank" rel="noreferrer">
                                                Support
                                            </Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link to="/admin/packages" className="menu-link px-2">
                                                Purchase
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
