import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { getListConversations } from "./conversationActions";
import { toBase64 } from "components/Function";
import { t } from "i18next";

const getListMessages =
    (page = 0, sizeperpage = 0, conversationId = "") =>
    (dispatch, getState) => {
        dispatch({ type: "LOADING_MESSAGE", payload: true });
        var param_search = "";
        // if (search !== "") {
        // 	param_search = `&search=${search}`;
        // }
        axios
            .get(
                `/api/message/list?conversationId=${conversationId}&page=${page}&sizeperpage=${sizeperpage}${param_search}`,
                tokenConfig(getState)
            )
            .then((res) => {
                dispatch({ type: "LOADING_MESSAGE", payload: false });
                if (res.data.status === 200) {
                    dispatch({
                        type: page !== 1 ? "PREPEND_MESSAGE" : "LIST_MESSAGE",
                        payload: res.data.data,
                    });
                    dispatch(getListConversations(1, 10));
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            })
            .catch((err) => {
                dispatch({ type: "LOADING_MESSAGE", payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const sendMessage = (data) => async (dispatch, getState) => {
    data.setMessage("");
    dispatch({
        type: "APPEND_MESSAGE",
        payload: {
            content: JSON.stringify({
                content: data.message,
            }),
            create_time: Math.floor(Date.now() / 1000),
        },
    });

    var body = {
        conversations_id: data.conversations_id,
        message: data.message,
    };
    return axios
        .post("/api/message/send", body, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                dispatch(getListConversations(1, 10));
                // Swal.fire(t("Success"), t(res.data.message), "success");
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const sendImageMessage = (conversations_tiktok_id, file) => async (dispatch, getState) => {
    var image_to_base64 = await toBase64(file[0]);
    dispatch({
        type: "APPEND_MESSAGE",
        payload: {
            content: JSON.stringify({
                url: image_to_base64,
            }),
            create_time: Math.floor(Date.now() / 1000),
        },
    });

    const form = new FormData();
    if (!file?.length) return false;
    for (var i = 0; i < file.length; i++) {
        form.append(`file[${i}]`, file[i]);
    }
    form.append("conversations_id", conversations_tiktok_id);

    const options = {
        method: "POST",
        url: "/api/message/image",
        headers: {
            "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
            "al-token": getState().user.token,
        },
        data: form,
    };

    return axios
        .request(options)
        .then((res) => {
            if (res.data.status === 200) {
                dispatch(getListConversations(1, 10));
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

export { getListMessages, sendMessage, sendImageMessage };
