import { useEffect, useState } from "react";
import { connect } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import NeedAction from "./needAction";
import Total from "./total";
// import Chart from "./chart";
import OnHold from "./onHold";
import { getTotalOrder, getDataChart, getOnHold } from "actions/analyticActions";
import { formatterCreateAt, convertTZ, getDaysArray } from "components/Function";
import { ListDateType } from "helper/constant";
import Loading from "components/Loading";

const Analytic = ({ getTotalOrder, getDataChart, getOnHold, formatterCreateAt }) => {
    const [data, setData] = useState({});
    // const [dataChart, setDataChart] = useState({});
    const [dataOnHold, setDataOnHold] = useState({});
    const [filter, setFilter] = useState({});
    const [dates, setDates] = useState([]);
    const [dateType, setDateType] = useState("today");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(filter)?.length) {
            setLoading(true);
            const total = getTotalOrder({ filter });
            // const chart = getDataChart({ filter });
            const onHold = getOnHold();

            Promise.all([total, onHold]).then(([t, o]) => {
                setData({ ...(t?.data || {}) });
                // setDataChart(c?.data || {});
                setDataOnHold(o?.data || {});
                setLoading(false);
            });
        }
    }, [getTotalOrder, getDataChart, getOnHold, filter]);

    useEffect(() => {
        if (filter?.dateStart && filter?.dateEnd) {
            setDates(getDaysArray(filter.dateStart, filter.dateEnd));
        }
    }, [filter]);

    useEffect(() => {
        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const handleEventDate = (start, end) => {
        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    const reloadOnHold = (shop) => {
        return getOnHold({ filter: { shop } }).then((o) => setDataOnHold(o?.data || {}));
    };

    return (
        <div className="col-xl-12 mb-10">
            <Loading isLoading={loading} />
            <NeedAction data={data} />
            <div className="d-flex gap-4 mt-4">
                <Select
                    name="dateType"
                    options={ListDateType.filter((v) => v.value)}
                    className="al-select"
                    classNamePrefix="select"
                    value={ListDateType.filter(({ value }) => value === dateType)}
                    onChange={(e) => {
                        setDateType(e?.value || "");
                    }}
                    placeholder="Select a date ... "
                />
                {dateType === "custom" && filter?.dateStart && filter?.dateEnd && (
                    <div>
                        <DateRangePicker
                            onCallback={handleEventDate}
                            initialSettings={{
                                startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                showDropdowns: true,
                                locale: { format: "DD/MM/YYYY" },
                            }}
                        >
                            <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                        </DateRangePicker>
                    </div>
                )}
            </div>
            <Total data={data} />
            {/* <Chart data={dataChart} dates={dates} /> */}
            <OnHold data={dataOnHold} reload={reloadOnHold} />
        </div>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { getTotalOrder, getDataChart, getOnHold, formatterCreateAt };

export default connect(mapStateToProps, mapDispatchToProps)(Analytic);
