import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getListPrinters, deletePrinter } from "actions/printerActions";
import { useTranslation } from "react-i18next";
import { formatterCreateAt, subStringText } from "components/Function";
import { ReactTable } from "components/Table";
import { Modaladdprinter } from "./modal";

function Listprinters(props) {
    const [isOpenModalAddPrinter, setIsOpenModalAddPrinter] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const { t } = useTranslation();
    var { listprinters, totalsize } = props.printer;
    var { getListPrinters, formatterCreateAt, deletePrinter, subStringText } = props;

    useEffect(() => {
        getListPrinters(page, sizePerPage, searchText);
    }, [getListPrinters, page, sizePerPage, searchText]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);


    const onClickOpenModalAddPrinter = () => {
        setIsOpenModalAddPrinter(!isOpenModalAddPrinter);
    };

    const onClickDeletePrinter = (row = [], formatExtraData) => {
        if (typeof formatExtraData !== "undefined") {
            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
            };
        }
        deletePrinter(row);
    };

    const columns = [{
        dataField: "_id",
        text: "",
        sort: true,
        formatter: (cell, row, rowIndex) => rowIndex + 1,
    }, {
        dataField: "printer",
        text: t("Printer"),
        sort: true,
    }, {
        dataField: "username",
        text: t("Username / Access token / Api key"),
        sort: true,
        formatter: (cell, row) => subStringText(cell || row?.access_token || row?.api_key),
    }, {
        dataField: "auto_place",
        text: t("Auto Place"),
        sort: true,
    }, {
        dataField: "created_at",
        text: t("Created At"),
        sort: true,
        formatter: (cell, row) => formatterCreateAt(cell)
    }, {
        dataField: "-",
        text: t("Actions"),
		formatExtraData: {page, sizePerPage, searchText},
        formatter: (cell, row, rowIndex, formatExtraData) => (
            <Fragment>
                <button onClick={() => { onClickDeletePrinter(row, formatExtraData); }} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Delete")} >
                    <i className="ki-outline ki-trash fs-2"></i>
                </button>
            </Fragment>
        ),
    }];

    const groupButton = () => {
        return (
            <div className="mb-5">
                <button onClick={() => { onClickOpenModalAddPrinter(); }} type="button" className="btn btn-primary btn-sm me-1">
                    <i className="ki-outline ki-plus fs-2"></i> {t("Add Printer")}
                </button>
            </div>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    };

    const sizePerPageList = [
        {
            text: "10", value: 10,
        },
        {
            text: "50", value: 50,
        },
        {
            text: "100", value: 100,
        },
        {
            text: "150", value: 150,
        },
        {
            text: "200", value: 200,
        },
        {
            text: "All", value: totalsize || 50,
        },
    ];

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <div className="al-variants-table">
                        <ReactTable columns={columns} data={listprinters} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" sizePerPageList={sizePerPageList} />
                        {isOpenModalAddPrinter ? <Modaladdprinter openModal={onClickOpenModalAddPrinter} isOpenModalAddPrinter={isOpenModalAddPrinter} /> : ""}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Listprinters.propTypes = {
    getListPrinters: PropTypes.func.isRequired,
    formatterCreateAt: PropTypes.func.isRequired,
    deletePrinter: PropTypes.func.isRequired,
    subStringText: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    printer: state.printer
});
const mapDispatchToProps = {
    getListPrinters,
    formatterCreateAt,
    deletePrinter,
    subStringText,
}

export default connect(mapStateToProps, mapDispatchToProps)(Listprinters);