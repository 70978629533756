import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cx from "classnames";
// import Loading from "components/Loading";
import TopSales from "./topsales";
import BreakoutProducts from "./breakoutproduct";
import NewProducts from "./newproduct";
import HotProduct from "./hotproduct";

function Main(props) {
    const [tab, setTab] = useState("topsales");
    const { t } = useTranslation();

    const onClick = (data) => {
        setTab(data);
    }

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-0 pb-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "topsales" })} to="/admin/spy/tiktok" onClick={(e) => { onClick("topsales") }}>{t("Top Sales")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "breakoutproduct" })} to="/admin/spy/tiktok" onClick={(e) => { onClick("breakoutproduct") }}>{t("Breakout Products")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "newproduct" })} to="/admin/spy/tiktok" onClick={(e) => { onClick("newproduct") }}>{t("New Products")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "hotproduct" })} to="/admin/spy/tiktok" onClick={(e) => { onClick("hotproduct") }}>{t("Hot Products")}</Link>
                        </li>
                    </ul>
                </div>
            </div>
            {/* <Loading isLoading={isLoading} /> */}
            {
                tab === "topsales" ?
                    <TopSales />
                    :
                    tab === "breakoutproduct" ?
                        <BreakoutProducts />
                        :
                        tab === "newproduct" ?
                            <NewProducts />
                            :
                            <HotProduct />
            }
        </Fragment>
    );
}

Main.propTypes = {
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);