const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0
};

function shopMessageReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_SHOP_MESSAGE':
			return {
				...state,
				isLoading: action?.payload
			};		
		case 'ADMIN_LIST_SHOP_MESSAGE':
			return {
				...state,
				list: action?.payload?.shops || [],
				totalsize: action?.payload?.totalsize || 0
			};
		default:
		  return state;
    }
}

export default shopMessageReducer;
