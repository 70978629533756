import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { saveEditPromotion } from "actions/promotionActions";
import { formatterCreateAt, getDiffDays } from "components/Function";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
// import DateRangePicker from "react-bootstrap-daterangepicker";

function Modaleditpromotion(props) {
    const [_id] = useState(props.editpromotion._id);
    const [title, setTitle] = useState(props.editpromotion.title);
    const [product_level, setProductLevel] = useState(props.editpromotion.product_level);
    const [activity_type, setActivityType] = useState(props.editpromotion.activity_type);
    const [activity_price_amount_first_variant, setActivityPriceAmountFirstVariant] = useState(props.editpromotion.activity_price_amount_first_variant);
    const [limit_first_varinat, setLimitFirstVariant] = useState(props.editpromotion.limit_first_varinat);
    const [activity_price_amount, setActivityPriceAmount] = useState(props.editpromotion.activity_price_amount);
    const [auto_renews, setAutoRenew] = useState(props?.editpromotion?.auto_renews || "false");

    // const [begin_time, setBeginTime] = useState(props.editpromotion.begin_time);
    // const [end_time, setEndTime] = useState(props.editpromotion.end_time);
    const [discount_period, setDiscountPeriod] = useState(props.editpromotion.discount_period);
    var { isLoading } = props.promotion;
    var { isOpenModalEditPromotion, openModal, saveEditPromotion } = props;
    const { t } = useTranslation();

    const onClickSaveEditPromotion = () => {
        saveEditPromotion({ _id, title, product_level, activity_type, activity_price_amount_first_variant, activity_price_amount, discount_period, limit_first_varinat, auto_renews }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "title") {
            setTitle(e.target.value);
        }
        if (e.target.name === "product_level") {
            setProductLevel(e.target.value);
        }
        if (e.target.name === "activity_type") {
            setActivityType(e.target.value);
        }
        if (e.target.name === "activity_price_amount_first_variant") {
            setActivityPriceAmountFirstVariant(e.target.value);
        }
        if (e.target.name === "limit_first_varinat") {
            setLimitFirstVariant(e.target.value);
        }
        if (e.target.name === "activity_price_amount") {
            setActivityPriceAmount(e.target.value);
        }
        if (e.target.name === "discount_period") {
            setDiscountPeriod(e.target.value);
        }
        if (e.target.name === "auto_renews") {
            setAutoRenew(e.target.value);
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    // const handleEventDate = (start, end) => {
    //     let date_start = new Date(start);
    //     setBeginTime(date_start.getTime());

    //     let date_end = new Date(end);
    //     setEndTime(date_end.getTime());
    // };

    const onKeyPress = (e) => {
        if ((/^[0-9.]+$/).test(e)) {
            e.preventDefault();
        }
    }

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalEditPromotion}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add Promotion")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Title")}</span>
                        </label>
                        <input type="text" className="form-control" value={title} placeholder="" name="title" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Product level")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a Activity Type" value={product_level} name="product_level" onChange={(e) => { onChange(e) }} >
                            <option value="">{t("-- Select a product level --")}</option>
                            <option value="PRODUCT">Product</option>
                            <option value="VARIATION">Variation</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Activity type")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a Activity Type" value={activity_type} name="activity_type" onChange={(e) => { onChange(e) }} >
                            <option value="">{t("-- Select a activity type --")}</option>
                            {/* <option value="FIXED_PRICE">Fixed price</option> */}
                            <option value="DIRECT_DISCOUNT">Direct discount</option>
                            <option value="FLASHSALE">Flash sale</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Activity price amount")}</span>
                        </label>
                        <div className="input-group">
                            <input type="number" className="form-control" value={activity_price_amount} placeholder="" name="activity_price_amount" onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                            <span className="input-group-text">%</span>
                        </div>
                        <div className="form-text text-danger">
                            <i>Max 90%</i>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="">{t("Activity price amount for the first variant")}</span>
                        </label>
                        <div className="input-group">
                            <input type="number" className="form-control" value={activity_price_amount_first_variant} placeholder="" name="activity_price_amount_first_variant" onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                            <span className="input-group-text">%</span>
                        </div>
                        <div className="form-text text-danger">
                            <i>Customize the active price for the first variation or fill in 0 to follow the price set above</i>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="">{t("Limit quantity")}</span>
                        </label>
                        <div className="input-group">
                            <input type="number" className="form-control" value={limit_first_varinat} placeholder="" name="limit_first_varinat" onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                        </div>
                        <div className="form-text text-danger">
                            <i>Limit quantity for first variant</i>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Discount period")}</span>
                        </label>
                        <div className="input-group">
                            <input type="number" className="form-control" value={discount_period} placeholder="" name="discount_period" onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} />
                            <span className="input-group-text">{t("hours")}</span>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Auto Renew")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="" value={auto_renews} name="auto_renews" onChange={(e) => { onChange(e) }} >
                            <option value="false">Disable</option>
                            {/* <option value="DIRECT_DISCOUNT">Direct discount</option> */}
                            <option value="true">Enable</option>
                        </select>
                    </div>
                    {/* <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Begin time and End time")}</span>
                        </label>
                        <DateRangePicker onCallback={handleEventDate} initialSettings={{ drops: "up",timePicker: true, timePicker24Hour: true, startDate: formatterCreateAt(begin_time), endDate: formatterCreateAt(end_time), showDropdowns: true, locale: {format: 'DD/MM/YYYY HH:mm'}, minDate: new Date() }}>
                            <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                        </DateRangePicker>
                    </div> */}
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEditPromotion() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaleditpromotion.propTypes = {
    formatterCreateAt: PropTypes.func.isRequired,
    saveEditPromotion: PropTypes.func.isRequired,
    getDiffDays: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    promotion: state.promotion
});
const mapDispatchToProps = {
    formatterCreateAt,
    saveEditPromotion,
    getDiffDays
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaleditpromotion);