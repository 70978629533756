import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import { getRecharge, deleteRecharge } from "actions/rechargeActions";
import { formatterCreateAt, FormatterStatus } from "components/Function";
import AddRecharge from "./add";

function Recharge({ getRecharge, formatterCreateAt, FormatterStatus, deleteRecharge }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState(50);
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        getRecharge({ page, sizeperpage: sizePerPage, search: searchText }).then((data) => {
            setData(data?.list || []);
            setTotalSize(data?.totalsize || 0);
            setLoading(false);
        });
    }, [getRecharge, searchText, page, sizePerPage]);

    const loadData = () => {
        setLoading(true);
        getRecharge({ page, sizeperpage: sizePerPage, search: searchText }).then((data) => {
            setData(data?.list || []);
            setTotalSize(data?.totalsize || 0);
            setLoading(false);
        });
    };

    const handleDelete = async (id) => {
        setLoading(true);
        await deleteRecharge({ id: id });
        loadData();
    };

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "provider",
            text: "Provider",
            sort: true,
        },
        {
            dataField: "tId",
            text: "Transition",
            sort: true,
        },
        {
            dataField: "status",
            text: t("Status"),
            sort: true,
            formatter: (cell, row) => <FormatterStatus status={cell.toLowerCase()} />,
        },
        {
            dataField: "created_at",
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatExtraData: { page, sizePerPage, searchText },
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <Fragment>
                    {row.status === "Pending" && (
                        <button
                            className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={t("Delete")}
                            onClick={() => handleDelete(row._id)}
                        >
                            <i className="ki-outline ki-trash fs-2"></i>
                        </button>
                    )}
                </Fragment>
            ),
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalSize || 50,
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    };

    return (
        <Fragment>
            <Loading isLoading={loading} />
            <div className="card mb-5 mb-xl-10">
                <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                >
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0">{t("Recharge")}</h3>
                    </div>
                </div>
                <div className="kt_account_settings_profile_details">
                    <div className="card-body border-top p-9">
                        <div className="al-variants-table">
                            <ReactTable
                                columns={columns}
                                data={data}
                                groupButton={() => (
                                    <div className="mb-5">
                                        <AddRecharge onSubmit={loadData} />
                                    </div>
                                )}
                                handleTableChange={handleTableChange}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                alClassName="table-layout-inherit"
                                sizePerPageList={sizePerPageList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { getRecharge, formatterCreateAt, FormatterStatus, deleteRecharge };

export default connect(mapStateToProps, mapDispatchToProps)(Recharge);
