import { connect } from "react-redux";

function Loading(props) {
    const { isLoading } = props;

    return (
        <div
            className="loading w-100 h-100"
            style={{
                display: isLoading ? "block" : "none",
                position: "fixed",
                left: 0,
                top: 0,
                background: "#ffffff54",
                zIndex: "9999",
            }}
        >
            <div className="h-100 d-flex justify-content-center align-items-center">
                <div className="cs-absolute-center ">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
                <div className="loading-bg"></div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
