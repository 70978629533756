const initialState = {
	isLoading: false,
	listconversations: [],
	totalsize: 0,
};

function conversationReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_CONVERSATION':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LIST_CONVERSATION':
			return {
				...state,
				listconversations: action.payload.listconversations,
				totalsize: action.payload.totalsize
			};
		case 'APPEND_CONVERSATION':
				return {
					...state,
					listconversations: [...state.listconversations, action.payload],
					totalsize: action.payload.totalsize
				};
		default:
		  return state;
    }
}

export default conversationReducer;
