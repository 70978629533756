import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactTable } from "components/Table";
import { CreateStaff, EditStaff } from "./modalForm";
import { createStaff, getStaffs, deleteStaff, updateStaff } from "actions/teamActions";
import { useTranslation } from "react-i18next";
import { getKeyByValue } from "components/Function";
import { TeamRole } from "helper/constant";
import Loading from "components/Loading";

const UserStaff = ({ createStaff, getStaffs, deleteStaff, updateStaff, team, user }) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [staffSelect, setStaffSelect] = useState(0);
    const { t } = useTranslation();
    const { list, totalsize, isLoading } = team;

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "user",
            text: t("Username"),
            sort: true,
            formatter: (cell, row, rowIndex) => (
                <>
                    <span>{cell?.name}</span>
                    <br />
                    <b>{cell?.email}</b>
                </>
            ),
        },
        {
            dataField: "role",
            text: t("Role"),
            sort: true,
            formatter: (cell, row, rowIndex) => getKeyByValue(TeamRole, cell),
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <>
                    <button
                        onClick={() => {
                            setStaffSelect(row);
                        }}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit")}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </button>
                    <button
                        className="btn btn-icon btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Delete"
                        onClick={() => handleDelete(row._id)}
                    >
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button>
                </>
            ),
        },
    ];

    useEffect(() => {
        getStaffs({ page, sizeperpage: sizePerPage, search: searchText });
    }, [getStaffs, page, sizePerPage, searchText]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const handleCreateStaff = (data) => {
        return createStaff(data).then((r) => {
            if (r) getStaffs({ page, sizeperpage: sizePerPage, search: searchText });
            return r;
        });
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const groupButton = () => {
        return (
            <div className="mb-5">
                <CreateStaff onSubmit={handleCreateStaff} />      
                <span className="ml-5">Max Limit: {user?.user?.limit_staff || 0}</span>          
            </div>
        );
    };

    const handleDelete = async (id) => {
        const result = await deleteStaff({ id });
        if (result) getStaffs({ page, sizeperpage: sizePerPage, search: searchText });
    };

    const handleEdit = async (data) => {
        return updateStaff(data).then((r) => {
            if (r) getStaffs({ page, sizeperpage: sizePerPage, search: searchText });
            return r;
        });
    };

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table">
                        <ReactTable
                            columns={columns}
                            data={list || []}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit"
                            sizePerPageList={sizePerPageList}
                        />
                    </div>
                </div>
            </div>
            <EditStaff team={staffSelect} onClose={() => setStaffSelect(null)} onSubmit={handleEdit} />
        </>
    );
};

const mapStateToProps = ({ team, user }) => ({ team, user });
const mapDispatchToProps = { createStaff, getStaffs, deleteStaff, updateStaff };

export default connect(mapStateToProps, mapDispatchToProps)(UserStaff);
