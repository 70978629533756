const initialState = {
	isLoading: false,
    list: [],
	totalsize: 0
};

function transitionReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_ADMIN_TRANSITION':
			return {
				...state,
				isLoading: action.payload
			};
		case 'ADMIN_TRANSITION_USER':
			return {
				...state,
				list: action.payload.list,
				totalsize: action.payload.totalsize
			};
		default:
		  return state;
    }
}

export default transitionReducer;
