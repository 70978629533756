import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getListUsers, deleteUser, updateBalanceUser } from "actions/userActions";
import { formatterCreateAt, FormatterStatus } from "components/Function";
import { ReactTable } from "components/Table";
import { Modaladduser, Modaledituser, ModalUpdateBalance } from "./modal";

function Listusers(props) {
    const [isOpenModalAddUser, setIsOpenModalAddUser] = useState(false);
    const [isOpenModalEditUser, setIsOpenModalEditUser] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [userUpdateBalance, setUserUpdateBalance] = useState("");
	const [edituser, setEditUser] = useState([]);
    const { t } = useTranslation();
    var { listusers, totalsize } = props.user;
    var { getListUsers, formatterCreateAt, deleteUser, updateBalanceUser, FormatterStatus } = props;

    useEffect(() => {
        getListUsers(page, sizePerPage, searchText);
    }, [getListUsers, page, sizePerPage, searchText]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const onClickOpenModalAddUser = () => {
        setIsOpenModalAddUser(!isOpenModalAddUser);
    };
	
	const onClickOpenModalEditUser = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
        setIsOpenModalEditUser(!isOpenModalEditUser);
		setEditUser(row);
	};

    const onClickDeleteUser = (row = [], formatExtraData) => {
        if (typeof formatExtraData !== "undefined") {
            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
            };
        }
        deleteUser(row);
    };

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "email",
            text: t("Email"),
            sort: true,
        },
        {
            dataField: "balance",
            text: t("Balance"),
            sort: true,
        },
        {
            dataField: "staffCount",
            text: t("Staffs"),
            sort: true,
            formatter: (cell, row) => <>{cell} / {row?.limit_staff}</>,
        },
        {
            dataField: "shopCount",
            text: t("Shops"),
            sort: true,
            formatter: (cell, row) => <>{cell} / {row?.limit_shop}</>,
        },
        {
            dataField: 'block',
            text: t("Status"),
            sort: true,
            formatter: (cell, row) => <FormatterStatus status={!cell} />,
        },
        {
            dataField: "expired_at",
            text: t("Expired At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "created_at",
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatExtraData: {page, sizePerPage, searchText},
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <Fragment>
                    <button
                        onClick={() => {
                            onClickOpenModalEditUser(row, formatExtraData);
                        }}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit")}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </button>
                    <button
                        onClick={() => setUserUpdateBalance(row._id)}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit Balance")}
                    >
                        <i className="ki-outline ki-wallet fs-2"></i>
                    </button>
                    <button
                        onClick={() => {
                            onClickDeleteUser(row, formatExtraData);
                        }}
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Delete")}
                    >
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button>
                </Fragment>
            ),
        },
    ];

    const groupButton = () => {
        return (
            <div className="mb-5">
                <button
                    onClick={() => {
                        onClickOpenModalAddUser();
                    }}
                    type="button"
                    className="btn btn-primary btn-sm me-1"
                >
                    <i className="ki-outline ki-plus fs-2"></i> {t("Add User")}
                </button>
            </div>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    };

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const handleChangeBalance  = async (data) => {
        await updateBalanceUser(data);
        getListUsers(page, sizePerPage, searchText);
    }

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <div className="al-variants-table">
                        <ReactTable
                            columns={columns}
                            data={listusers}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit"
                            sizePerPageList={sizePerPageList}
                        />
                        {isOpenModalAddUser ? (
                            <Modaladduser openModal={onClickOpenModalAddUser} isOpenModalAddUser={isOpenModalAddUser} />
                        ) : (
                            ""
                        )}
                        {isOpenModalEditUser ? (
                            <Modaledituser openModal={onClickOpenModalEditUser} isOpenModalEditUser={isOpenModalEditUser} edituser={edituser} />
                        ) : (
                            ""
                        )}
                        <ModalUpdateBalance userId={userUpdateBalance} onClose={() => setUserUpdateBalance("")} onSubmit={handleChangeBalance} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Listusers.propTypes = {
    getListUsers: PropTypes.func.isRequired,
    formatterCreateAt: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    FormatterStatus: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {
    getListUsers,
    formatterCreateAt,
    deleteUser,
    updateBalanceUser,
    FormatterStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Listusers);
