import { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { updateDesign } from "actions/designAction";
import ReactImageMagnify from 'react-image-magnify';
import { formatterImage } from "components/Function";

const ModalEditApp = ({ data, onClose, onSubmit, updateDesign }) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState(data?.title || '');
    const [keyword, setKeyword] = useState(data?.keyword || '');
    const [position_front, setPositionFront] = useState(data?.design?.Front);
    const [position_back, setPositionBack] = useState(data?.design?.Back);
    const [position_neck, setPositionNeck] = useState(data?.design?.Neck);
    const [position_right, setPositionRight] = useState(data?.design?.Right);
    const [position_left, setPositionLeft] = useState(data?.design?.Left);
    const [position_aop_3d, setPositionAOP3D] = useState(data?.design?.AOP_3D);

    const [mockup_front, setMockupFront] = useState(data?.mockup?.Front);
    const [mockup_back, setMockupBack] = useState(data?.mockup?.Back);
    const [mockup_neck, setMockupNeck] = useState(data?.mockup?.Neck);
    const [mockup_right, setMockupRight] = useState(data?.mockup?.Right);
    const [mockup_left, setMockupLeft] = useState(data?.mockup?.Left);
    const [mockup_aop_3d, setMockupAOP3D] = useState(data?.mockup?.AOP_3D);

    const onChange = (e) => {
        if (e.target.name === "title") {
            setTitle(e.target.value)
        }
        if (e.target.name === "keyword") {
            setKeyword(e.target.value);
        }
        if (e.target.name === "position_front") {
            setPositionFront(e.target.value);
        }
        if (e.target.name === "position_back") {
            setPositionBack(e.target.value);
        }
        if (e.target.name === "position_neck") {
            setPositionNeck(e.target.value);
        }
        if (e.target.name === "position_right") {
            setPositionRight(e.target.value);
        }
        if (e.target.name === "position_left") {
            setPositionLeft(e.target.value);
        }
        if (e.target.name === "position_aop_3d") {
            setPositionAOP3D(e.target.value);
        }
        if (e.target.name === "mockup_front") {
            setMockupFront(e.target.value);
        }
        if (e.target.name === "mockup_back") {
            setMockupBack(e.target.value);
        }
        if (e.target.name === "mockup_neck") {
            setMockupNeck(e.target.value);
        }
        if (e.target.name === "mockup_right") {
            setMockupRight(e.target.value);
        }
        if (e.target.name === "mockup_left") {
            setMockupLeft(e.target.value);
        }
        if (e.target.name === "mockup_aop_3d") {
            setMockupAOP3D(e.target.value);
        }
    }

    const handleSubmit = async () => {
        const mockup = {
            Front: mockup_front,
            Back: mockup_back,
            Neck: mockup_neck,
            Right: mockup_right,
            Left: mockup_left,
            AOP_3D: mockup_aop_3d
        }
        const design = {
            Front: position_front,
            Back: position_back,
            Neck: position_neck,
            Right: position_right,
            Left: position_left,
            AOP_3D: position_aop_3d
        }
        await updateDesign({ _id: data?._id, title, keyword, design, mockup, page: data?.page, sizeperpage: data?.sizeperpage, search: data?.search, filter: data?.filter })
        onSubmit?.();
        // onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!data}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit App")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onClose(); }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Title")}</span>
                        </label>
                        <input type="text" className="form-control" value={title} placeholder={t("Title")} name="title" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Keyword")}</span>
                        </label>
                        <input type="text" className="form-control" value={keyword} placeholder={t("Keyword")} name="keyword" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="mb-5">
                        <div className="mb-5">
                            <div className="h3">DESIGN</div>
                            <div className="d-flex justify-content-around mb-5">
                                <div className="d-flex align-items-center justify-content-around flex-column">
                                    <span className="h4">Front</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_front ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_front) }, largeImage: { src: formatterImage(position_front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_front} placeholder={t("Front")} name="position_front" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column ml-5">
                                    <span className="h4">Back</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_back ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_back) }, largeImage: { src: formatterImage(position_back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_back} placeholder={t("Back")} name="position_back" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Neck</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_neck ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_neck) }, largeImage: { src: formatterImage(position_neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_neck} placeholder={t("Neck")} name="position_neck" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-around mb-5">
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Right</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_right ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_right) }, largeImage: { src: formatterImage(position_right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_right} placeholder={t("Right")} name="position_right" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Left</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_left ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_left) }, largeImage: { src: formatterImage(position_left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_left} placeholder={t("Left")} name="position_left" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">AOP 3D</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            position_aop_3d ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(position_aop_3d) }, largeImage: { src: formatterImage(position_aop_3d), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={position_aop_3d} placeholder={t("AOP 3D")} name="position_aop_3d" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div>
                            <div className="h3">MOCKUP</div>
                            <div className="d-flex justify-content-around mb-5">
                                <div className="d-flex align-items-center justify-content-around flex-column">
                                    <span className="h4">Front</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_front ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_front) }, largeImage: { src: formatterImage(mockup_front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_front} placeholder={t("Front")} name="mockup_front" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column ml-5">
                                    <span className="h4">Back</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_back ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_back) }, largeImage: { src: formatterImage(mockup_back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_back} placeholder={t("Back")} name="mockup_back" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Neck</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_neck ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_neck) }, largeImage: { src: formatterImage(mockup_neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_neck} placeholder={t("Neck")} name="mockup_neck" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-around mb-5">
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Right</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_right ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_right) }, largeImage: { src: formatterImage(mockup_right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_right} placeholder={t("Right")} name="mockup_right" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">Left</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_left ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_left) }, largeImage: { src: formatterImage(mockup_left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_left} placeholder={t("Left")} name="mockup_left" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-around flex-column mr-5">
                                    <span className="h4">AOP 3D</span>
                                    <div className="symbol symbol-100px me-2">
                                        {
                                            mockup_aop_3d ?
                                                <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_aop_3d) }, largeImage: { src: formatterImage(mockup_aop_3d), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                        }
                                        <input type="text" className="form-control" value={mockup_aop_3d} placeholder={t("AOP 3D")} name="mockup_aop_3d" onChange={(e) => { onChange(e) }} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={handleSubmit}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({ app: state.app });

export default connect(mapStateToProps, { updateDesign })(ModalEditApp);
