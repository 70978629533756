import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTable } from "components/Table";
import { getShops, deleteShop, refreshToken, updateMemberShop, cancelDeleteShop, syncInfomation, syncOrders } from "actions/shopActions";
import { getStaffs } from "actions/teamActions";
import AddShop from "./addShop";
import EditShop from "./editShop";
import ModalMember from "./modalMember";
import ViewSizeChart from "./viewSizeChart";
import { permissionComponent, FormatterStatus, formatterCreateAt } from "components/Function";
import Loading from "components/Loading";
import SelectBox from "components/elements/selectBox";

const Shop = ({
    getShops,
    shop,
    deleteShop,
    user,
    refreshToken,
    getStaffs,
    updateMemberShop,
    FormatterStatus,
    cancelDeleteShop,
    formatterCreateAt,
    syncInfomation,
    syncOrders,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [shopSelect, setShopSelect] = useState(null);
    const [editMember, setEditMember] = useState(null);
    const [viewChart, setViewChart] = useState(null);
    const [openMultipleMember, setOpenMultipleMember] = useState(false);
    const [shopsSelect, setShopsSelect] = useState([]);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [sort_by, setSortBy] = useState("");
    const [sort_order, setSortOrder] = useState("");

    const { t } = useTranslation();
    const { list, totalsize, isLoading } = shop;

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
            onSort: (field, order) => {
                setSortBy(field);
                setSortOrder(order);
            }
        },
        {
            dataField: "name",
            text: t("Name"),
            sort: true,
            formatter: (cell, row) => <>
                <div className="d-flex mb-5 h5">{cell}</div>
                {row?.warehouses?.filter((v, i) => v?.effect_status === "ENABLED" && v?.type === "SALES_WAREHOUSE")?.length > 0 && <div className="d-flex">
                    <i>
                        Warehouses Sale: {row?.warehouses?.filter((v, i) => v?.effect_status === "ENABLED" && v?.type === "SALES_WAREHOUSE")?.length}
                    </i>
                </div>}
                {row?.warehouses?.filter((v, i) => v?.effect_status === "ENABLED" && v?.type === "RETURN_WAREHOUSE")?.length > 0 && <div className="d-flex">
                    <i>
                        Warehouses Return: {row?.warehouses?.filter((v, i) => v?.effect_status === "ENABLED" && v?.type === "RETURN_WAREHOUSE")?.length}
                    </i>
                </div>}
                {row?.size_charts?.length > 0 && <div className="d-flex">
                    <i>
                        Size Charts: {row?.size_charts?.length} <button
                            onClick={() => {
                                setViewChart(row?.size_charts);
                            }}
                            className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={t("View")}
                        >
                            <i className="ki-outline ki-eye fs-4"></i>
                        </button>
                    </i>
                </div>}
            </>,
        },
        {
            dataField: "region",
            text: t("Region"),
            sort: true,
        },
        {
            dataField: "status",
            text: t("Status"),
            sort: true,
            formatter: (cell, row) => <FormatterStatus status={cell || "Connected"} />,
        },
        {
            dataField: "sync_product_status",
            text: t("Status Sync"),
            sort: true,
            formatter: (cell, row) => {
                let type = "success";
                if (cell?.status === "Running") type = "warning";
                if (cell?.status === "Error") type = "Error";
                return (
                    <>
                        <FormatterStatus type={type} status={cell?.status || "End"} />
                        <p className="mt-2">Last Sync: {cell?.last_sync && cell?.last_sync > 0 ? formatterCreateAt(cell?.last_sync) : "Since 1905"}</p>
                    </>
                );
            },
        },
        {
            dataField: "code",
            text: t("Code"),
            sort: true,
        },
        {
            dataField: "orders_count",
            text: t("Total Order"),
            sort: true,
            onSort: (field, order) => {
                setSortBy(field);
                setSortOrder(order);
            }
        },
        {
            dataField: "products_count",
            text: t("Total Product"),
            sort: true,
        },
        {
            dataField: "note",
            text: t("Note"),
            sort: true,
            style: {
                maxWidth: "200px",
                wordBreak: "break-all",
                whiteSpace: "pre-line",
            },
            formatter: (cell, row) => cell,
        },
        {
            dataField: "member_note",
            text: t("Member Note"),
            sort: true,
            style: {
                maxWidth: "200px",
                wordBreak: "break-all",
                whiteSpace: "pre-line",
            },
            formatter: (cell, row) => cell,
        },
        {
            dataField: "last_order",
            text: t("Last Order"),
            sort: true,
            formatter: (cell, row) => cell && cell > 0 ? formatterCreateAt(cell) : 'Since 1905',

        },
        {
            dataField: "memberData",
            text: t("Members"),
            sort: true,
            formatter: (cell, row, rowIndex) =>
                cell?.map((v, i) => (
                    <p>
                        - {v?.name} ({v?.email})
                    </p>
                )),
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatter: (cell, row, rowIndex, formatExtraData) =>
                permissionComponent({
                    user: user.user,
                    permission: "UPDATE_SHOP",
                    component:
                        row.status !== "Deleted" ? (
                            <>
                                <button
                                    onClick={() => {
                                        setShopSelect(row);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Edit Note")}
                                >
                                    <i className="ki-outline ki-notepad-edit fs-2"></i>
                                </button>
                                <button
                                    onClick={() => {
                                        if (row?.app?.authorization_link)
                                            window.location.href = row?.app?.authorization_link;
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Reconnect")}
                                >
                                    <i className="ki-outline ki-disk fs-2"></i>
                                </button>
                                <button
                                    onClick={() => {
                                        setEditMember(row);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Member")}
                                >
                                    <i className="ki-outline ki-user fs-2"></i>
                                </button>
                                {/* <button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Refresh Token"
                                    onClick={() => refreshToken(row._id)}
                                >
                                    <i className="ki-outline ki-arrows-loop fs-2"></i>
                                </button> */}
                                <button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Delete"
                                    onClick={() => handleDelete(row._id)}
                                >
                                    <i className="ki-outline ki-trash fs-2"></i>
                                </button>
                            </>
                        ) : (
                            <button
                                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Cancel Delete"
                                onClick={() => handleCancelDelete(row._id)}
                            >
                                <i className="ki-outline ki-delete-folder fs-2"></i>
                            </button>
                        ),
                }),
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const onSelect = (row, isSelect) => {
        let shopsSelectTmp = shopsSelect;
        if (isSelect) {
            shopsSelectTmp.push(row._id);
        } else {
            shopsSelectTmp = shopsSelectTmp.filter((shopId) => shopId !== row._id);
        }
        const new_arr = JSON.stringify(shopsSelectTmp);
        setShopsSelect(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        let shopsSelectTmp = shopsSelect;
        if (isSelect) {
            shopsSelectTmp = row.map((v, i) => v._id);
        } else {
            shopsSelectTmp = [];
        }
        setShopsSelect(shopsSelectTmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: shopsSelect,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({shopsSelect.length})</span>
            </div>
        ),
    };

    useEffect(() => {
        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
    }, [getShops, page, sizePerPage, searchText, filter, sort_by, sort_order]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const handleUpdateMembers = (data) => {
        return updateMemberShop({ members: data?.members || [], _id: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleSyncInfomation = async (data) => {
        return syncInfomation({ shops: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order })
    };

    const handleSyncOrders = async (data) => {
        return syncOrders({ shops: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order })
    };

    const handleRefreshToken = async (data) => {
        return refreshToken({ shops: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order })
    };

    const groupButton = () => {
        return (
            <div className="mb-5 d-flex gap-2">
                <AddShop />
                <button onClick={() => setOpenMultipleMember(true)} className="btn btn-info">
                    Update member
                </button>
                <ModalMember
                    data={{}}
                    isOpen={openMultipleMember}
                    onSubmit={handleUpdateMembers}
                    onClose={() => setOpenMultipleMember(false)}
                />
                <span onClick={handleSyncInfomation} className="btn btn-dark" role="button">
                    Sync Infomation
                </span>
                <span onClick={handleSyncOrders} className="btn btn-warning" role="button">
                    Sync Orders
                </span>
                <span onClick={handleRefreshToken} className="btn btn-info" role="button">
                    Refresh Token
                </span>
                <SelectBox
                    options={staffs}
                    name="filter_staff"
                    value={filter.staff || ""}
                    onChange={(data) => {
                        setFilter((prev) => ({
                            ...prev,
                            staff: data.value || "",
                        }));
                        setPage(1);
                    }}
                />
            </div>
        );
    };

    const handleDelete = (id) => {
        deleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleCancelDelete = (id) => {
        cancelDeleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    />
                </div>
                <EditShop
                    data={shopSelect}
                    onClose={() => setShopSelect(null)}
                    onSubmit={() => {
                        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
                    }}
                />
                <ModalMember
                    isOpen={!!editMember}
                    data={editMember}
                    onClose={() => setEditMember(null)}
                    onSubmit={(data) =>
                        updateMemberShop({
                            ...data,
                            page,
                            sizePerPage,
                            searchText,
                            filter,
                            sort_by,
                            sort_order
                        })
                    }
                />
                <ViewSizeChart
                    isOpen={!!viewChart}
                    data={viewChart}
                    onClose={() => setViewChart(null)}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ shop, user }) => ({ shop, user });
const mapDispatchToProps = {
    getShops,
    deleteShop,
    refreshToken,
    getStaffs,
    updateMemberShop,
    FormatterStatus,
    cancelDeleteShop,
    formatterCreateAt,
    syncInfomation,
    syncOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
