const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0,
};

function tiktokTopSalesReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_TIKTOK_TOP_SALES':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_TIKTOK_TOP_SALES':
			return {
				...state,
				list: action?.payload?.product_rising_rank || [],
				totalsize: action?.payload?.totalsize || 0
			};
		default:
			return state;
	}
}

export default tiktokTopSalesReducer;
