import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import Swal from "sweetalert2";
import { countString } from "components/Function";
import { Link } from "react-router-dom";
import _ from "lodash";

function ModalImport({ onSubmit, template, data, allData, countString }) {
    const [isOpen, setIsOpen] = useState(false);
    const [templateID, setTemplateID] = useState("");
    const [otherImage, setOtherImage] = useState("");
    const [number_image, setNumberImage] = useState(1);
    const [textOld, setTextOld] = useState("");
    const [textNew, setTextNew] = useState("");
    const [textStart, setTextStart] = useState("");
    const [textEnd, setTextEnd] = useState("");
    const [dataSelect, setDataSelect] = useState([]);

    useEffect(() => {
        setDataSelect(allData?.filter((v, i) => data.join('').includes(v?._id?.toString())))
    }, [allData, data]);

    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "otherImage") {
            setOtherImage(e.target.value);
        }
        if (e.target.name === "number_image") {
            setNumberImage(e.target.value);
        }
        if (e.target.name === "textOld") {
            setTextOld(e.target.value);
        }
        if (e.target.name === "textNew") {
            setTextNew(e.target.value);
        }
        if (e.target.name.includes('title_')) {
            var id_change = e.target.name?.split('title_')?.[1];
            var value_new = e.target.value;
            var new_title = dataSelect?.map((v, i) => {
                if (v.id === id_change) {
                    v.title = value_new;
                }
                return v;
            })
            setDataSelect(new_title);
        }
        if (e.target.name === "textStart") {
            setTextStart(e.target.value);
        }
        if (e.target.name === "textEnd") {
            setTextEnd(e.target.value);
        }
    }

    const onChangeTemplate = (e) => {
        setTemplateID(e.value)
    }

    const onDelete = async (id) => {
        setDataSelect(dataSelect?.filter((v, i) => v?.id !== id))
    };

    const handleSubmit = () => {
        const mapData = dataSelect?.map((v, i) => {
            return { id: v.id, image: v.image, title: v.title?.replace(/&#39;/g, "'"), image_other: otherImage?.split('\n') || [], template: templateID, number_image: 0, }
        })
        onSubmit(mapData);
        setIsOpen(false);
    };

    const handleChangeText = () => {
        var new_change_title = dataSelect?.map((v, i) => {
            let textStart_ = textStart ? textStart + " " : "";
            let textEnd_ = textEnd ? " " + textEnd : "";
            let change_text = textStart_ + v?.title?.toLowerCase().split(textOld?.toLowerCase()).join((textNew || "")?.toLowerCase()) + textEnd_;
            let new_title = change_text?.split(' ')?.map((v, i) => v?.[0]?.toUpperCase() + v?.slice(1))?.join(' ');
            v.title = new_title;
            return v;
        });
        new_change_title = _.uniqBy(new_change_title, "id");
        setDataSelect([]);
        setDataSelect(new_change_title);
        return Swal.fire(t("Success"), t("Change Success!"), "success");
    };

    return (
        <Fragment>
            <button onClick={() => setIsOpen(true)} type="button" className="btn btn-primary btn-sm me-1">
                <i className="fa fa-cloud-upload mr-1"></i> {t("Import To System")}
            </button>
            <Modal
                centered={true}
                size="xl"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-info">Change Data Before Upload</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex justify-content-between mb-5">
                        <div className="form-group">
                            <label className="required">Template</label>
                            <div>
                                <Select
                                    isClearable
                                    name="templateID"
                                    options={template}
                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-2 al-filter-table"
                                    classNamePrefix="select"
                                    value={template.filter(({ value }) => templateID === value)}
                                    onChange={(e) => { onChangeTemplate(e) }}
                                    placeholder={t("Select a template ... ")}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Number Image Etsy</label>
                            <div>
                                <input type="number" className="form-control" value={number_image} placeholder={t("1")} name="number_image" min={1} onChange={(e) => { onChange(e) }} />
                            </div>
                        </div>
                    </div>
                    <span><i className="text-danger">{"If Image Number Etsy < 2 recommends adding images via URL. Please ensure a minimum of 5 photos for your product."}</i></span>
                    <div className="form-group">
                        <label>Other Image Secondary by URL</label>
                        <div className="mb-5">
                            <textarea
                                className="form-control"
                                type="text"
                                name="otherImage"
                                placeholder="https://image.com/1.png
https://image.com/2.jpg
https://image.com/3.gif
..."
                                rows={3}
                                defaultValue={otherImage}
                                onChange={(e) => { onChange(e) }}
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            {
                                otherImage?.split('\n').length > 0 && otherImage?.split('\n')?.map((v, i) => <>
                                    <div>
                                        <img className="img-thumbnail p-2" alt="" src={v} width={150} />
                                    </div>
                                </>)
                            }
                        </div>
                        <div className="d-flex justify-content-around">
                            <div className="form-group">
                                <label>Text Old</label>
                                <div>
                                    <input type="text" className="form-control" value={textOld} placeholder={t("Disney")} name="textOld" onChange={(e) => { onChange(e) }} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Change To</label>
                                <div>
                                    <input type="text" className="form-control" value={textNew} placeholder={t("Djsney")} name="textNew" onChange={(e) => { onChange(e) }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Merge Start</label>
                                        <div>
                                            <input type="text" className="form-control" value={textStart} placeholder={t("")} name="textStart" onChange={(e) => { onChange(e) }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Merge End</label>
                                        <div>
                                            <input type="text" className="form-control" value={textEnd} placeholder={t("")} name="textEnd" onChange={(e) => { onChange(e) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label></label>
                                <div>
                                    <button
                                        onClick={handleChangeText}
                                        type="submit"
                                        id=""
                                        className="btn btn-sm btn-info"
                                    >
                                        {t("Change Text")}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr></hr>
                    {dataSelect.length > 0 && <><div style={{ maxHeight: "800px", overflow: "auto" }}>
                        <table className="table table-striped">
                            <thead style={{ position: "sticky", top: "0", background: "#fff " }}>
                                <tr className="card-title text-gray-800 fw-bold">
                                    <th scope="col">ID</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Image</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {dataSelect.map((v, k) => <><tr key={k}>
                                    <td>
                                        <div className="d-flex justify-content-evenly">
                                            <Link to={v.link}>{v.id}</Link>
                                            <button type="button" className="btn btn-sm btn-danger" onClick={() => { onDelete(v.id) }}><i className="ki-outline ki-tablet-delete fs-2" ></i></button>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={v?.title?.length < 140 && v?.title?.length > 40 ? "text-success" : "text-danger"}>Total: {v?.title?.length} <span className="text-info">{countString(v?.title)}</span></div>
                                        <textarea type="text" rows={10} cols={30} name={"title_" + v.id} value={v.title} onChange={(e) => { onChange(e) }} >{v.title}</textarea>
                                    </td>
                                    <td><Link to={v.url}><img className="img-responsive" alt={v.title} src={v.image} width={250} /></Link></td>
                                </tr></>
                                )}
                            </tbody>
                        </table>
                    </div>

                    </>}
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Start Upload")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    countString,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalImport);
