import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { getProductTemplate } from "actions/productTemplateAction";
import { getCategories } from "actions/categoryAction";
import { TreeSelect } from "antd";
import Select from "react-select";

function ModalTemplate({ onSubmit, productTemplate, getProductTemplate, getCategories }) {
    const [isOpen, setIsOpen] = useState(false);
    const [templateID, setTemplateID] = useState("");
    const [otherImage, setOtherImage] = useState("");
    const [numberImage, setNumberImage] = useState(9);
    const [category, setCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [removePrdImport, setRemovePrdImport] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        getProductTemplate();
    }, [getProductTemplate]);

    useEffect(() => {
        getCategories().then((data) =>
            setCategories((data || []).map((c) => ({ value: c.id, label: c.local_name, children: c.children })))
        );
    }, [getCategories]);

    const onChangeTemplate = (e) => {
        setTemplateID(e?.value || "");
    };

    const handleSubmit = () => {
        onSubmit({
            templateId: templateID,
            categoryId: category,
            otherImage: otherImage ? otherImage?.split("\n") : [],
            numberImage: numberImage,
            removePrdImport,
        });
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button onClick={() => setIsOpen(true)} type="button" className="btn btn-primary btn-sm me-1">
                <i className="fa fa-cloud-upload mr-1"></i> {t("Import To System")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-info">You're preparing to upload products to your account</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex justify-content-between mb-5 w-100 flex-column gap-4">
                        <div className="form-group w-100">
                            <label className="required">Template</label>
                            <div>
                                <Select
                                    isClearable
                                    name="templateID"
                                    options={productTemplate}
                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table w-100"
                                    classNamePrefix="select"
                                    value={productTemplate.filter(({ value }) => templateID === value)}
                                    onChange={(e) => {
                                        onChangeTemplate(e);
                                    }}
                                    placeholder={t("Select a template ... ")}
                                />
                            </div>
                        </div>
                        {/* <div className="form-group w-100">
                            <label className="required">Category</label>
                            <div>
                                <TreeSelect
                                    showSearch
                                    style={{ width: "100%" }}
                                    treeNodeFilterProp="label"
                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                    className="h-100"
                                    placeholder="Please select"
                                    onChange={(cate) => {
                                        setCategory(cate);
                                    }}
                                    treeData={categories}
                                    value={category}
                                />
                            </div>
                        </div> */}
                        <div className="form-group w-100">
                            <label className="required">Remove Product Import Success</label>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                    <input
                                        className="form-check-input w-45px h-30px"
                                        type="checkbox"
                                        id="manage_product"
                                        checked={removePrdImport}
                                        name="manage_product"
                                        onChange={(e) => {
                                            setRemovePrdImport(e.target.checked);
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="manage_product"></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group w-100">
                            <label className="required">Total Image:</label>
                            <div>
                                <input
                                    type="number"
                                    value={numberImage}
                                    min={1}
                                    name="numberImage"
                                    className="form-control"
                                    onChange={(e) => {
                                        setNumberImage(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Other Image Secondary by URL</label>
                            <div className="mb-5">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="otherImage"
                                    placeholder="https://image.com/1.png
https://image.com/2.jpg
https://image.com/3.gif
..."
                                    rows={5}
                                    defaultValue={otherImage}
                                    onChange={(e) => {
                                        setOtherImage(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="row">
                                {otherImage?.split("\n").length > 0 &&
                                    otherImage?.split("\n")?.map((v, i) => (
                                        <div key={i} className="col-3">
                                            <img className="responsive p-2" alt="" src={v} width={200} />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Start Upload")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    productTemplate: state.productTemplate?.list?.map((v, i) => {
        return { value: v._id, label: v.title };
    }),
});
const mapDispatchToProps = { getProductTemplate, getCategories };

export default connect(mapStateToProps, mapDispatchToProps)(ModalTemplate);
