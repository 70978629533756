import { Fragment, useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getListConversations, syncConversationsMessages } from "actions/conversationActions";
import { getListMessages, sendMessage, sendImageMessage } from "actions/messageActions";
import { getShops } from "actions/shopActions";
import { Link } from "react-router-dom";
import Loading from "components/Loading";
import { initSocket, socket } from "socket";
import moment from "moment";
import store from "store";
import Avatar from "react-avatar";
import cx from "classnames";
import EmojiPicker from "emoji-picker-react";
import Select from "react-select";

function Main(props) {
    const [page_conversation] = useState(1);
    const [sizePerPage_conversation] = useState(10);
    const [shops, setShops] = useState([]);
    const [filter, setFilter] = useState({});
    const [searchText_conversation, setSearchTextConversation] = useState("");
    // const [totalSize_conversation, setTotalSizeConversation] = useState(0);
    const [conversationSelect, setConversationSelect] = useState(null);
    var { listconversations } = props.conversation;

    const [page_message, setPageMessage] = useState(1);
    const [sizePerPage_message] = useState(10);
    // const [searchText_message, setSearchTextMessage] = useState("");
    const [totalSize_message, setTotalSizeMessage] = useState(0);
    var { listmessages, totalsize: totalsize_message } = props.message;

    const [message, setMessage] = useState("");
    const [toggle, setToggle] = useState(false);
    const [scroll_potion, setScrollPosition] = useState(0);
    const { t } = useTranslation();
    const divRef = useRef(null);
    const inputFileRef = useRef(null);
    const scrollMessageRef = useRef(null);
    var { list } = props.shop;
    var { getListConversations, getListMessages, syncConversationsMessages, sendMessage, getShops, sendImageMessage } =
        props;
    var isLoading = props.conversation.isLoading || props.message.isLoading;

    const listshop = useMemo(
        () =>
            list?.map((v, i) => {
                return { label: `${v?.name} - ${v?.note}`, value: v?.apiShopId };
            }),
        [list]
    );

    useEffect(() => {
        getListConversations(page_conversation, sizePerPage_conversation, searchText_conversation, filter);
    }, [getListConversations, page_conversation, sizePerPage_conversation, searchText_conversation, filter]);

    // useEffect(() => {
    //     setTotalSizeConversation(totalsize_conversation);
    // }, [totalsize_conversation]);

    useEffect(() => {
        if (conversationSelect) {
            getListMessages(page_message, sizePerPage_message, conversationSelect.conversations_tiktok_id);
        }
    }, [getListMessages, page_message, sizePerPage_message, conversationSelect]);

    useEffect(() => {
        setTotalSizeMessage(totalsize_message);
    }, [totalsize_message]);

    useEffect(() => {
        getShops({ page: 1, sizeperpage: 9999 });
    }, [getShops]);

    useEffect(() => {
        initSocket();
        socket.on("new_message", function (data) {
            if (conversationSelect?.conversations_tiktok_id === data.data.conversation_id) {
                store.dispatch({
                    type: "APPEND_MESSAGE",
                    payload: data.data,
                });
            }
            getListConversations(1, 10);
        });
    }, [getListConversations, conversationSelect]);

    useEffect(() => {
        if (scroll_potion === -1) {
            divRef?.current?.scrollIntoView({ behaviour: "smooth" });
        } else {
            if (scrollMessageRef.current) {
                const scrollContainer = scrollMessageRef?.current;
                const oldScrollHeight = scrollContainer?.scrollHeight;
                scrollMessageRef.current.scrollTop = oldScrollHeight - scroll_potion;
            }
        }
    }, [listmessages]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (e) => {
        if (e.target.name === "message") {
            setMessage(e.target.value);
        }
        if (e.target.name === "search") {
            setSearchTextConversation(e.target.value);
        }
    };

    const onClickSyncConversationsMessages = () => {
        syncConversationsMessages({ setConversationSelect });
    };

    const onClickSendMessage = () => {
        sendMessage({ conversations_id: conversationSelect.conversations_tiktok_id, message, setMessage });
        setScrollPosition(-1);
        divRef?.current?.scrollIntoView({ behaviour: "smooth" });
    };

    const onClickToggle = () => {
        setToggle(!toggle);
    };

    const onBtnClick = () => {
        inputFileRef.current.click();
    };

    const handleScroll = (e) => {
        if (e.target.scrollTop === 0) {
            if (totalSize_message >= page_message * 10) {
                const scrollContainer = scrollMessageRef?.current;
                const oldScrollHeight = scrollContainer?.scrollHeight;
                setScrollPosition(oldScrollHeight);
                setPageMessage(page_message + 1);
            }
        }
    };

    const getMessageContent = (data) => {
        try {
            const mess = JSON.parse(data);
            if (mess?.url)
                return (
                    <img
                        style={{ width: "100px", height: "100px", objectFit: "cover" }}
                        alt="message"
                        src={mess?.url}
                    />
                );
            return mess.content;
        } catch {
            return "";
        }
    };

    const onFileChangeCapture = (e) => {
        sendImageMessage(conversationSelect.conversations_tiktok_id, e.target.files);
        setScrollPosition(-1);
        divRef?.current?.scrollIntoView({ behaviour: "smooth" });
    };

    const onKeyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
            sendMessage({ conversations_id: conversationSelect.conversations_tiktok_id, message, setMessage });
            setScrollPosition(-1);
            divRef?.current?.scrollIntoView({ behaviour: "smooth" });
        }
    };

    const onChangeShop = (e) => {
        var shops_tmp = e.map((v, i) => {
            return v.value;
        });
        setFilter((prev) => ({
            ...prev,
            shops: shops_tmp || [],
        }));
        setShops(shops_tmp);
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="d-flex flex-column flex-lg-row">
                <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
                    <div className="card card-flush">
                        <div className="card-header pt-7" id="kt_chat_contacts_header">
                            <div className="w-100 mb-4">
                                <Select
                                    isClearable
                                    isMulti
                                    name="shop"
                                    options={listshop}
                                    className="cotik-select-virtual"
                                    classNamePrefix="select"
                                    value={listshop.filter(({ value }) => shops.includes(value))}
                                    onChange={(e) => {
                                        onChangeShop(e);
                                    }}
                                    placeholder={t("Select a shop ... ")}
                                    theme={(theme) => ({
                                        ...theme,
                                        spacing: {
                                            ...theme.spacing,
                                            controlHeight: "100%",
                                            controlWidth: "200px",
                                        },
                                    })}
                                />
                            </div>
                            <div className="w-100 position-relative">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <i className="ki-outline ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y"></i>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid px-13"
                                            name="search"
                                            placeholder="Search by username..."
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-3" style={{ textAlign: "right" }}>
                                        <button
                                            onClick={() => {
                                                onClickSyncConversationsMessages();
                                            }}
                                            className="btn btn-primary"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={t("Sync Conversations Messages")}
                                        >
                                            <i className="ki-outline ki-arrows-loop fs-2 m-0 p-0"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-5" id="kt_chat_contacts_body">
                            <div className="scroll-y me-n5 pe-5 h-200px h-lg-auto" style={{ maxHeight: "584px" }}>
                                <div className="separator separator-dashed d-none"></div>
                                {listconversations.map((item, key) => (
                                    <div
                                        onClick={() => {
                                            setScrollPosition(-1);
                                            setPageMessage(1);
                                            setConversationSelect(item);
                                        }}
                                        className={cx("d-flex flex-stack py-4 cursor-pointer p-3", {
                                            "bg-secondary":
                                                conversationSelect?.conversations_tiktok_id ===
                                                item?.conversations_tiktok_id,
                                        })}
                                        key={key}
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="symbol  symbol-45px symbol-circle ">
                                                {item?.participants?.[1]?.avatar ? (
                                                    <img src={item?.participants?.[1]?.avatar} alt="" />
                                                ) : (
                                                    <Avatar
                                                        name={item?.participants?.[1]?.nickname}
                                                        size="45"
                                                        round={true}
                                                    />
                                                )}
                                                <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div>
                                            </div>
                                            <div className="ms-5">
                                                <Link
                                                    to="#"
                                                    className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                                                >
                                                    {item?.participants?.[1]?.nickname || t("unknow")}
                                                </Link>
                                                <div className="fw-semibold text-muted">
                                                    {t("Shop")}:{" "}
                                                    {list?.find((v, i) => v.apiShopId === item.shop_id)?.name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-end ms-2">
                                            <span className="text-muted fs-7 mb-1 text-end">
                                                {moment
                                                    .unix(
                                                        Math.floor(
                                                            +(item?.latest_message?.create_time || Date.now() / 1000)
                                                        )
                                                    )
                                                    .fromNow()}
                                            </span>
                                            {item.unread_count ? (
                                                <span
                                                    className="badge badge-sm badge-circle badge-light-danger"
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content={t("Unread")}
                                                >
                                                    {item.unread_count}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {!!conversationSelect && (
                    <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                        <div className="card" id="kt_chat_messenger">
                            <div className="card-header" id="kt_chat_messenger_header">
                                <div className="card-title">
                                    <div className="d-flex justify-content-center flex-column me-3">
                                        <div className="symbol  symbol-45px symbol-circle ">
                                            {conversationSelect?.participants?.[1]?.avatar ? (
                                                <img src={conversationSelect?.participants?.[1]?.avatar} alt="" />
                                            ) : (
                                                <Avatar
                                                    name={conversationSelect?.participants?.[1]?.nickname}
                                                    size="45"
                                                    round={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center flex-column me-3">
                                        <Link
                                            to="#"
                                            className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                                        >
                                            {conversationSelect?.participants?.[1]?.nickname || t("Unknow")}
                                        </Link>
                                        <div className="mb-0 lh-1">
                                            <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                            <span className="fs-7 fw-semibold text-muted">{t("Active")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" id="kt_chat_messenger_body">
                                <div
                                    className="scroll-y me-n5 pe-5 h-300px h-lg-auto"
                                    style={{ maxHeight: "436px" }}
                                    onScroll={(e) => handleScroll(e)}
                                    ref={scrollMessageRef}
                                >
                                    {listmessages.map((message, key) =>
                                        message?.type === "NOTIFICATION" || message?.type === "OTHER" ? (
                                            ""
                                        ) : message?.sender?.role === "BUYER" ? (
                                            <div key={key} className="d-flex justify-content-start mb-10 ">
                                                <div className="d-flex flex-column align-items-start">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <div className="symbol  symbol-35px symbol-circle ">
                                                            {message?.sender?.avatar ? (
                                                                <img src={message?.sender?.avatar} alt="" />
                                                            ) : (
                                                                <Avatar
                                                                    name={message?.sender?.nickname}
                                                                    size="45"
                                                                    round={true}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="ms-3">
                                                            <Link
                                                                to="#"
                                                                className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                                                            >
                                                                {message?.sender?.nickname}
                                                            </Link>
                                                            <span className="text-muted fs-7 mb-1">
                                                                {moment
                                                                    .unix(
                                                                        Math.floor(
                                                                            +(message?.create_time || Date.now() / 1000)
                                                                        )
                                                                    )
                                                                    .fromNow()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start"
                                                        data-kt-element="message-text"
                                                    >
                                                        {getMessageContent(message.content)}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={key} className="d-flex justify-content-end mb-10 ">
                                                <div className="d-flex flex-column align-items-end">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <div className="me-3">
                                                            <span className="text-muted fs-7 mb-1">
                                                                {moment
                                                                    .unix(
                                                                        Math.floor(
                                                                            +(message?.create_time || Date.now() / 1000)
                                                                        )
                                                                    )
                                                                    .fromNow()}
                                                            </span>
                                                            <Link
                                                                to="#"
                                                                className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                                                            >
                                                                {t("You")}
                                                            </Link>
                                                        </div>
                                                        <div className="symbol  symbol-35px symbol-circle ">
                                                            <img
                                                                alt=""
                                                                src={require("components/assets/media/avatars/300-23.jpg")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end"
                                                        data-kt-element="message-text"
                                                    >
                                                        {getMessageContent(message.content)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                    <div ref={divRef}></div>
                                </div>
                            </div>
                            <div className="card-footer pt-4" id="kt_chat_messenger_footer">
                                <textarea
                                    className="form-control form-control-flush mb-3"
                                    rows="1"
                                    data-kt-element="input"
                                    placeholder="Type a message... Enter to send..."
                                    name="message"
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    value={message}
                                    onKeyPress={(e) => {
                                        onKeyPress(e);
                                    }}
                                ></textarea>
                                <div className="d-flex flex-stack">
                                    <div className="d-flex align-items-center me-2">
                                        <button
                                            className="btn btn-sm btn-icon btn-active-light-primary me-1"
                                            type="button"
                                            onClick={() => onBtnClick()}
                                        >
                                            <label>
                                                <i className="ki-outline ki-paper-clip fs-3 cursor-pointer"></i>
                                            </label>
                                        </button>
                                        <input
                                            type="file"
                                            className="d-none"
                                            ref={inputFileRef}
                                            onChangeCapture={onFileChangeCapture}
                                        />
                                        <button
                                            className="btn btn-sm btn-icon btn-active-light-primary me-1"
                                            type="button"
                                            onClick={() => onClickToggle()}
                                        >
                                            <label>
                                                <span className="cursor-pointer">😀</span>
                                            </label>
                                        </button>
                                        <div className={cx("al-emo-css", { "d-none": !toggle })}>
                                            <EmojiPicker
                                                searchDisabled="true"
                                                previewConfig={{ showPreview: false }}
                                                emojiStyle="facebook"
                                                onEmojiClick={(e) => {
                                                    setToggle(false);
                                                    setMessage((prev) => prev + e.emoji);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        data-kt-element="send"
                                        onClick={() => onClickSendMessage()}
                                    >
                                        {t("Send")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
}

Main.propTypes = {
    getListConversations: PropTypes.func.isRequired,
    getListMessages: PropTypes.func.isRequired,
    syncConversationsMessages: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    getShops: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    conversation: state.conversation,
    message: state.message,
    shop: state.shop,
});
const mapDispatchToProps = {
    getListConversations,
    getListMessages,
    syncConversationsMessages,
    sendMessage,
    sendImageMessage,
    getShops,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
