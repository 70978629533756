import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { updateApp } from "actions/appActions";

const ModalEditApp = ({ data, onClose, onSubmit, updateApp }) => {
    const [formData, setFormData] = useState({ ...data });
    const { t } = useTranslation();
    useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        let d = formData;
        await updateApp(d);
        onSubmit?.();
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!data}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit App")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onClose(); }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Name")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.name || ""} placeholder={t("Name")} name="name" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("App ID")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.app_id || ""} placeholder={t("App ID")} name="app_id" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("App Key")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.app_key || ""} placeholder={t("App Key")} name="app_key" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("App Secret")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.app_secret || ""} placeholder={t("App Secret")} name="app_secret" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Redirect URL")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.redirect_url || ""} placeholder={t("Redirect URL")} name="redirect_url" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Webhook URL")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.webhook_url || ""} placeholder={t("Webhook URL")} name="webhook_url" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("App URL")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.app_url || ""} placeholder={t("App URL")} name="app_url" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Authorization Link")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.authorization_link || ""} placeholder={t("Authorization Link")} name="authorization_link" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Target")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a Region Target" value={formData?.target || "VN"} name="target" onChange={handleChange} >
                            <option value="">{t("-- Select a region target --")}</option>
                            <option value="US">United States</option>
                            <option value="UK">United Kingdom</option>
                            <option value="VN">Viet Nam</option>
                            <option value="OTHER">Other</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Status")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Status" value={formData?.status || "inactive"} name="status" onChange={handleChange} >
                            <option value="inactive">Inactive</option>
                            <option value="active">Active</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("App Type")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="type" value={formData?.app_type || "basic"} name="app_type" onChange={handleChange} >
                            <option value="basic">Basic</option>
                            <option value="message">Message</option>
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={handleSubmit}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({ app: state.app });

export default connect(mapStateToProps, { updateApp })(ModalEditApp);
