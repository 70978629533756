import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';

function ModalImport({ onSubmit, data, template, dataLink }) {
    const [isOpen, setIsOpen] = useState(false);
    const [templateID, setTemplateID] = useState("");
    const [otherImage, setOtherImage] = useState("");
    const [number_image, setNumberImage] = useState(1);
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "otherImage") {
            setOtherImage(e.target.value);
        }
        if (e.target.name === "number_image") {
            setNumberImage(e.target.value);
        }
    }
    const onChangeTemplate = (e) => {
        setTemplateID(e.value)
    }

    const handleSubmit = () => {
        const mapData = dataLink?.filter((v, i) => data.join('').includes(v?.id?.toString()))?.map((v, i) => {
            return { id: v.id, image: v.cover, title: v.name, image_other: otherImage?.split('\n') || [], template: templateID, number_image: number_image, cover: v.cover, image_others: v?.image_others || [], shop_unique_id: v?.shop_unique_id }
        })
        onSubmit(mapData);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button onClick={() => setIsOpen(true)} type="button" className="btn btn-primary btn-sm me-1">
                <i className="fa fa-cloud-upload mr-1"></i> {t("Import To System")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-info">You're preparing to upload {data.length} products to your account from Etsy</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex justify-content-between mb-5">
                        <div className="form-group">
                            <label className="required">Template</label>
                            <div>
                                <Select
                                    isClearable
                                    name="templateID"
                                    options={template}
                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-2 al-filter-table"
                                    classNamePrefix="select"
                                    value={template.filter(({ value }) => templateID === value)}
                                    onChange={(e) => { onChangeTemplate(e) }}
                                    placeholder={t("Select a template ... ")}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Number Image</label>
                            <div>
                                <input type="number" className="form-control" value={number_image} placeholder={t("1")} name="number_image" min={1} onChange={(e) => { onChange(e) }}/>
                            </div>
                        </div>
                    </div>
                    <span><i className="text-danger">{"If Image Number < 2 recommends adding images via URL. Please ensure a minimum of 5 photos for your product."}</i></span>
                    <div className="form-group">
                        <label>Other Image Secondary by URL</label>
                        <div className="mb-5">
                            <textarea
                                className="form-control"
                                type="text"
                                name="otherImage"
                                placeholder="https://image.com/1.png
https://image.com/2.jpg
https://image.com/3.gif
..."
                                rows={5}
                                defaultValue={otherImage}
                                onChange={(e) => { onChange(e) }}
                            />
                        </div>
                        <div className="row">
                            {
                                otherImage?.split('\n').length > 0 && otherImage?.split('\n')?.map((v, i) => <>
                                    <div className="col-3">
                                        <img className="responsive p-2" alt="" src={v} width={200} />
                                    </div>
                                </>)
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Start Upload")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalImport);
