const initialState = {
	isLoading: false,
	notification_order: {},
	notification_shop: {},
	notification_shipping: {},
	notification_return_order: {},
	notification_message_warning: {},
	notification_warning_ship: {},
	notification_cancel_order: {},
};

function emailReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_NOTIFICATION':
			return {
				...state,
				isLoading: action?.payload
			};
		case 'ADMIN_NOTIFICATION':
			return {
				...state,
				notification_order: action?.payload?.notification_order || {},
				notification_shop: action?.payload?.notification_shop || {},
				notification_shipping: action?.payload?.notification_shipping || {},
				notification_return_order: action?.payload?.notification_return_order || {},
				notification_message_warning: action?.payload?.notification_message_warning || {},
				notification_warning_ship: action?.payload?.notification_warning_ship || {},
				notification_cancel_order: action?.payload?.notification_cancel_order || {},
			};
		default:
			return state;
	}
}

export default emailReducer;
