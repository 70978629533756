import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ReactTable } from "components/Table";
import { Link } from "react-router-dom";

const ViewSizeChart = ({ isOpen, data, onClose }) => {
    const [formData, setFormData] = useState({ ...data });
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalSize, setTotalSize] = useState(0);

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: formData?.length || 50,
        },
    ];

    useEffect(() => {
        setTotalSize(formData?.length);
    }, [formData]);

    useEffect(() => {
        setFormData(data || []);
    }, [data]);


    const handleSubmit = async () => {
        onClose?.();
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
            onSort: (field, order) => {
            }
        },
        {
            dataField: "template_name",
            text: t("Name"),
            sort: true,
            formatter: (cell, row) => <>
                <div className="d-flex mb-5 h5">{cell}</div>
                <div className="d-flex mb-5"><i>{row.template_id}</i></div>
            </>,
        },
        {
            dataField: "images",
            text: t("Image"),
            sort: true,
            formatter: (cell, row) => <>
                <div className="d-flex justify-content-center mb-5">
                    {
                        cell?.map((v, i) => {
                            return (
                                <Fragment>
                                    <div className="mb-3" style={{ minHeight: "220px" }}>
                                        <div className="symbol symbol-50px me-2">
                                            {
                                                v?.url ?
                                                    <Link to={v?.url} target="_blank">
                                                        <img src={v?.url} alt={v?.url} width={600} />
                                                    </Link>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                            }
                                        </div>
                                    </div>
                                    {i + 1 !== cell.length ? <hr /> : ""}
                                </Fragment>
                            );
                        })
                    }
                </div>
            </>,
        },
        {
            dataField: "-",
            text: t("Actions"),
        },
    ];

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!isOpen}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Size Chart")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable
                            columns={columns}
                            data={formData || []}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit"
                            sizePerPageList={sizePerPageList}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        {t("Close")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSizeChart);
