import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactTable } from "components/Table";
import { searchTikTok } from "actions/spytoolActions";
import Loading from "components/Loading";
import Swal from "sweetalert2";
import { formatterCreateAt, countString } from "components/Function";
import ReactImageMagnify from 'react-image-magnify';

function Main({ user, countString, formatterCreateAt, searchTikTok }) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(100);
    const { t } = useTranslation();
    const [list, setList] = useState([]);
    const [products_selected, setProductsSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchProductName, setSearchProductName] = useState("");
    var { expired_at } = user.user;

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "id",
            text: t("Product"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment>
                {row?.content_type === "video" && <video controls autostart="false" mediatype="video" data-index="-1" src={"https://d15xsrghoj5xvi.cloudfront.net/video/" + cell + ".mp4?"}></video>}
            </Fragment>,
        },
        {
            dataField: "description",
            text: t("Description"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment>
                <div className="d-flex flex-column">
                    <div className="h4">
                        {cell}
                    </div>
                    <div>
                        <i>Created at: <b>{row?.creatorDebut}</b></i>
                    </div>
                    <div>
                        Time: <b>{row?.duration}</b>
                    </div>
                    {/* <div>
                        Price: <b>{row?.unit_price}</b>
                    </div> */}
                    <div>
                        View: <b>{row?.views}</b>
                    </div>
                    <div>
                        Sales: <b>{row?.sale}</b>
                    </div>
                    <div>
                        Revenue: <b>{row?.revenue}</b>
                    </div>
                    <div>
                        GPM: <b>{row?.gpm}</b>
                    </div>
                    <div>
                        Ad: <b>{row?.ad}</b>
                    </div>
                    <div>
                        Ad CPA: <b>{row?.ad_cpa}</b>
                    </div>
                    <div>
                        ID: <b>{row?.uid}</b>
                    </div>
                    <div>
                        Created by: <b>{row?.handle}</b>
                    </div>
                </div>
                <hr />
                <div>
                    Product:
                </div>
                <div className="d-flex">
                    {row?.products?.map((v, i) => <Link to={"https://shop.tiktok.com/view/product/" + v} target="_blank">
                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 200, height: 200, src: ("https://d3uucz7wx6jq40.cloudfront.net/tiktok.product/" + v + "/cover.png") || require("components/assets/media/misc/image.png") }, largeImage: { src: ("https://d3uucz7wx6jq40.cloudfront.net/tiktok.product/" + v + "/cover.png") || require("components/assets/media/misc/image.png"), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                    </Link>
                    )}
                </div>
            </Fragment>,
        },
    ];


    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row.id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row.id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = list.map((v, i) => v.id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({products_selected.length})</span>
            </div>
        ),
    };

    const groupButton = () => {
        return <div className="mb-5">
        </div>;
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(100);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const onClickSearch = async (data) => {
        setIsLoading(true)
        await searchTikTok({ keyword: searchProductName })
            .then((data) => {
                setList(data)
                setIsLoading(false)
                if (data.length > 0) {
                    return Swal.fire(t("Success"), data.length + " Videos", "success");
                }
                return Swal.fire(t("Error"), "Failed", "error");
            })
            .catch((err) => {
                return Swal.fire(t("Error"), err, "error");
            });
    };

    const handleTableSaveCell = (oldValue, newValue, row, column) => {
        row[column.dataField] = newValue;
        setList((prev) => {
            const data = prev.map((product) => {
                if (product.id === row.id) {
                    return { ...product, [column.dataField]: newValue };
                }
                return { ...product };
            });
            return [...data];
        });
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: list.length || 10
        }
    ];

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="d-flex gap-4">
                {
                    expired_at - Date.now() >= 0 ?
                        <Fragment>
                            <div className="d-flex">
                                <div className="form-group">
                                    <input type="text" value={searchProductName} name="searchProductName" className="form-control" placeholder="COTIK Video..." onChange={(e) => {
                                        setSearchProductName(e.target.value);
                                    }} />
                                </div>
                                <button onClick={onClickSearch} className="btn btn-info btn-sm me-1"><i className="ki-outline ki-filter-search"></i>{t("Search Video")}</button>
                            </div>
                            <span>From: {(new Date((Date.now() - 2592000000)).toISOString().split("T")[0])}</span>
                            <span>To: {(new Date((Date.now() - 172800000)).toISOString().split("T")[0])}</span>
                        </Fragment>
                        : ""
                }
            </div>
            <div className="card mb-5 mb-xl-10">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable
                            keyField="id"
                            columns={columns}
                            data={list}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={list.length}
                            alClassName="table-layout-inherit"
                            remote={{ search: false, pagination: false, sort: false }}
                            isSelectRow={true}
                            selectRow={selectRow}
                            isCellEdit={true}
                            handleBeforeSaveCell={handleTableSaveCell}
                            sizePerPageList={sizePerPageList}
                        />
                    </div>

                </div>
            </div>

        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = { countString, formatterCreateAt, searchTikTok };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
