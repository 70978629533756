import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AuthorizeRedirect = () => {
    let { search } = useLocation();
    useEffect(() => {
        window.location.href = `https://cotik.app/admin/shops/authorize/redirect${search}`;
    }, [search]);

    return <></>;
};

export default AuthorizeRedirect;
