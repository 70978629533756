const initialState = {
	isLoading: false,
};

function promotionReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_EXTENSION':
			return {
				...state,
				isLoading: action.payload
			};
		default:
		  return state;
    }
}

export default promotionReducer;
