import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { useTranslation } from "react-i18next";
// import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { permissionComponent } from "./Function";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

function Navbar(props) {
    const [toggleTab, setToggleTab] = useState("");
    const { t } = useTranslation();
    var { role } = props.user.user;
    var { toggleSidebar, setToggleSidebar } = props;
    var { pathname } = useLocation();

    useEffect(() => {
        if (pathname.indexOf("/extension-drop") > -1) {
            setToggleTab("extension-drop");
        }
        if (pathname.indexOf("/extension-pod") > -1) {
            setToggleTab("extension-pod");
        }
    }, [pathname]);

    const onClick = (data) => {
        if (data === toggleTab) {
            data = "";
        }
        setToggleTab(data);
    };

    return (
        <Fragment>
            <div
                id="kt_aside"
                className={cx("aside aside-dark aside-hoverable", {
                    drawer: isMobile && toggleSidebar,
                    "drawer-start": isMobile && toggleSidebar,
                    "drawer-on": isMobile && toggleSidebar,
                })}
                data-kt-drawer="true"
                data-kt-drawer-name="aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_aside_mobile_toggle"
            >
                <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                    <Link to={pathname.indexOf("/extension") > -1 ? "/admin/extension-etsy" : "/admin"}>
                        <img
                            alt="Logo"
                            src={require("components/assets/media/custom/logo.png")}
                            className="h-50px logo"
                        />
                    </Link>
                    <div
                        id="kt_aside_toggle"
                        className={cx("btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle me-n2", {
                            active: toggleSidebar,
                        })}
                        data-kt-toggle="true"
                        data-kt-toggle-state="active"
                        data-kt-toggle-target="body"
                        data-kt-toggle-name="aside-minimize"
                        onClick={() => setToggleSidebar(!toggleSidebar)}
                    >
                        <i className="ki-outline ki-double-left fs-1 rotate-180"></i>
                    </div>
                </div>
                <div className="aside-menu flex-column-fluid">
                    <div
                        className="hover-scroll-overlay-y"
                        id="kt_aside_menu_wrapper"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                        data-kt-scroll-wrappers="#kt_aside_menu"
                        data-kt-scroll-offset="0"
                        style={{ height: "calc(100vh - 65px)" }}
                    >
                        <div
                            className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                            id="#kt_aside_menu"
                            data-kt-menu="true"
                        >
                            {pathname.indexOf("/extension") < 0 ? (
                                <Fragment>
                                    <div className="menu-item">
                                        <div className="menu-content">
                                            {/* <span className="menu-heading fw-bold text-uppercase fs-7">Pages</span> */}
                                        </div>
                                    </div>
                                    {role === 1 && (
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/admin/manager">
                                                <span className="menu-icon">
                                                    <i className="ki-outline ki-rocket fs-2"></i>
                                                </span>
                                                <span className="menu-title">Admin</span>
                                            </NavLink>
                                        </div>
                                    )}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "USER_REAL",
                                        component: (
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/users-staff">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-address-book fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Users Staff</span>
                                                </NavLink>
                                            </div>
                                        ),
                                    })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "GET_PRODUCT",
                                        component: (
                                            <div
                                                data-kt-menu-trigger="click"
                                                className={cx("menu-item menu-accordion", {
                                                    show: toggleTab === "spyproduct",
                                                })}
                                            >
                                                <span className="menu-link" onClick={() => onClick("spyproduct")}>
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-satellite fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Spy Tools</span>
                                                    <span className="menu-arrow"></span>
                                                </span>
                                                <div className="menu-sub menu-sub-accordion">
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/spy/etsy">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-technology-4 fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Etsy Tool</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/spy/amazon">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-technology-2 fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Amazon Tool</span>
                                                        </NavLink>
                                                    </div>
                                                    {/* <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/spy/tiktokv1">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-tiktok fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">TikTok Tool</span>
                                                        </NavLink>
                                                    </div> */}
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/spy/tiktokv2">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-tiktok fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">TikTok Tool</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/spy/suggests">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-night-day fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Suggests</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/spy/tiktok">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-tiktok fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">TikTok Product</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "GET_ORDER",
                                        component: (
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/orders">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-handcart fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">{t("Orders")}</span>
                                                </NavLink>
                                            </div>
                                        ),
                                    })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "GET_PRODUCT",
                                        component: (
                                            <div
                                                data-kt-menu-trigger="click"
                                                className={cx("menu-item menu-accordion", {
                                                    show: toggleTab === "product",
                                                })}
                                            >
                                                <span className="menu-link" onClick={() => onClick("product")}>
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-abstract-26 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Products</span>
                                                    <span className="menu-arrow"></span>
                                                </span>
                                                <div className="menu-sub menu-sub-accordion">
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/Products">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-category fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Product Systems</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/product-schedule">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-calendar-tick fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Schedule Upload</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/product-template">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-code fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Template</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/categories">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-microsoft fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Category Product</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/product-tiktoks">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-tiktok fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Product TikToks</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/product-freeze">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-cross-square fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Product Freeze</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    })}

                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "GET_PROMOTION",
                                        component: (
                                            <div
                                                data-kt-menu-trigger="click"
                                                className={cx("menu-item menu-accordion", {
                                                    show: toggleTab === "promotions",
                                                })}
                                            >
                                                <span className="menu-link" onClick={() => onClick("promotions")}>
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-discount fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Promotions</span>
                                                    <span className="menu-arrow"></span>
                                                </span>
                                                <div className="menu-sub menu-sub-accordion">
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/promotions">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-tag fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Setup</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/promotions-logs">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-cheque fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Logs</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "GET_AFFILIATE",
                                        component: (
                                            <div
                                                data-kt-menu-trigger="click"
                                                className={cx("menu-item menu-accordion", {
                                                    show: toggleTab === "affiliates",
                                                })}
                                            >
                                                <span className="menu-link" onClick={() => onClick("affiliates")}>
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-data fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Affiliate</span>
                                                    <span className="menu-arrow"></span>
                                                </span>
                                                <div className="menu-sub menu-sub-accordion">
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/find-creators">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-filter-search fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Find Creators</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/collaboration">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-lots-shopping fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Collaboration</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/invite-creators">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-people fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Invite Creators</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    })}
                                    {props.user.user.teamRole !== "staff" &&
                                        permissionComponent({
                                            user: props.user.user,
                                            permission: "GET_SHOP",
                                            component: (
                                                <div
                                                    data-kt-menu-trigger="click"
                                                    className={cx("menu-item menu-accordion", {
                                                        show: toggleTab === "shops",
                                                    })}
                                                >
                                                    <span className="menu-link" onClick={() => onClick("shops")}>
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-discount fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Shops</span>
                                                        <span className="menu-arrow"></span>
                                                    </span>
                                                    <div className="menu-sub menu-sub-accordion">
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/shops">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-shop fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Shops</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/shops-message">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-cheque fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Shops Message</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/shops-promotion">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-cheque fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Shops Promotion</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                        })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "GET_MESSAGE",
                                        component: (
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/messages">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-message-text-2 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Messages</span>
                                                </NavLink>
                                            </div>
                                        ),
                                    })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "USER_REAL",
                                        component: (
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/packages">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-package fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Packages</span>
                                                </NavLink>
                                            </div>
                                        ),
                                    })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "GET_ORDER",
                                        component: (
                                            <div
                                                data-kt-menu-trigger="click"
                                                className={cx("menu-item menu-accordion", {
                                                    show: toggleTab === "returncancel",
                                                })}
                                            >
                                                <span className="menu-link" onClick={() => onClick("returncancel")}>
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-arrows-circle fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Return - Refund - Cancel</span>
                                                    <span className="menu-arrow"></span>
                                                </span>
                                                <div className="menu-sub menu-sub-accordion">
                                                    {permissionComponent({
                                                        user: props.user.user,
                                                        permission: "GET_ORDER_RETURN",
                                                        component: (
                                                            <div className="menu-item">
                                                                <NavLink className="menu-link" to="/admin/returns">
                                                                    <span className="menu-icon">
                                                                        <i className="ki-outline ki-call fs-2"></i>
                                                                    </span>
                                                                    <span className="menu-title">Returns</span>
                                                                </NavLink>
                                                            </div>
                                                        ),
                                                    })}
                                                    {permissionComponent({
                                                        user: props.user.user,
                                                        permission: "USER_REAL",
                                                        component: (
                                                            <div className="menu-item">
                                                                <NavLink className="menu-link" to="/admin/refunds">
                                                                    <span className="menu-icon">
                                                                        <i className="ki-outline ki-information fs-2"></i>
                                                                    </span>
                                                                    <span className="menu-title">Refunds</span>
                                                                </NavLink>
                                                            </div>
                                                        ),
                                                    })}
                                                    {permissionComponent({
                                                        user: props.user.user,
                                                        permission: "GET_ORDER_CANCEL",
                                                        component: (
                                                            <div className="menu-item">
                                                                <NavLink className="menu-link" to="/admin/cancels">
                                                                    <span className="menu-icon">
                                                                        <i className="ki-outline ki-cross-circle fs-2"></i>
                                                                    </span>
                                                                    <span className="menu-title">Cancels</span>
                                                                </NavLink>
                                                            </div>
                                                        ),
                                                    })}
                                                </div>
                                            </div>
                                        ),
                                    })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "USER_REAL",
                                        component: (
                                            <div
                                                data-kt-menu-trigger="click"
                                                className={cx("menu-item menu-accordion", {
                                                    show: toggleTab === "finances",
                                                })}
                                            >
                                                <span className="menu-link" onClick={() => onClick("finances")}>
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-cheque fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Finances</span>
                                                    <span className="menu-arrow"></span>
                                                </span>
                                                <div className="menu-sub menu-sub-accordion">
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/finances/statements">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-lots-shopping fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Statements</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/finances/withdrawals">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-exit-up fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Withdrawals</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/finances/payments">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-bank fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Payments</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    })}
                                    {permissionComponent({
                                        user: props.user.user,
                                        permission: "USER_REAL",
                                        component: (
                                            <div
                                                data-kt-menu-trigger="click"
                                                className={cx("menu-item menu-accordion", {
                                                    show: toggleTab === "designed",
                                                })}
                                            >
                                                <span className="menu-link" onClick={() => onClick("designed")}>
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-design fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Design</span>
                                                    <span className="menu-arrow"></span>
                                                </span>
                                                <div className="menu-sub menu-sub-accordion">
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/design/manage">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-design-2 fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Manage</span>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-item">
                                                        <NavLink className="menu-link" to="/admin/design/buy">
                                                            <span className="menu-icon">
                                                                <i className="ki-outline ki-cheque fs-2"></i>
                                                            </span>
                                                            <span className="menu-title">Buy Design</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    })}
                                    <div className="menu-item">
                                        <NavLink className="menu-link" to="/admin/notification">
                                            <span className="menu-icon">
                                                <i className="ki-outline ki-notification-on fs-2"></i>
                                            </span>
                                            <span className="menu-title">Notification</span>
                                        </NavLink>
                                    </div>
                                </Fragment>
                            ) : (
                                ""
                            )}
                            {pathname.indexOf("/extension") > -1 ? (
                                <Fragment>
                                    <div
                                        data-kt-menu-trigger="click"
                                        className={cx("menu-item menu-accordion", { show: toggleTab === "extension-drop" })}
                                    >
                                        <span className="menu-link" onClick={() => onClick("extension-drop")}>
                                            <span className="menu-icon">
                                                <i className="ki-outline ki-dropbox fs-2"></i>
                                            </span>
                                            <span className="menu-title">Extension Dropship</span>
                                            <span className="menu-arrow"></span>
                                        </span>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-taobao">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-delivery-3 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Taobao</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-amazon">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-technology-2 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Amazon</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-ebay">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-shop fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Ebay</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-tiktok">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-tiktok fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Tiktok</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-walmart">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-abstract-12 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Walmart</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-aliexpress">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-courier-express fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Aliexpress</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-temu">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-purchase fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Temu</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-cj">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-ship fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">CJ</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-1688">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-airplane fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">1688</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-thgfulfill">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-delivery-geolocation fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">THG Fulfill</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-alibaba">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-shop fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Alibaba</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-drop-other">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-parcel-tracking fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Other</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        data-kt-menu-trigger="click"
                                        className={cx("menu-item menu-accordion", {
                                            show: toggleTab === "extension-pod",
                                        })}
                                    >
                                        <span className="menu-link" onClick={() => onClick("extension-pod")}>
                                            <span className="menu-icon">
                                                <i className="ki-outline ki-element-plus fs-2"></i>
                                            </span>
                                            <span className="menu-title">Extension POD</span>
                                            <span className="menu-arrow"></span>
                                        </span>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-etsy">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-tag fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Etsy</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-amazon">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-technology-2 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Amazon</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-ebay">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-shop fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Ebay</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-pinterest">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-picture fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Pinterest</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-google">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-google fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Google shopping</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-tiktok">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-tiktok fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Tiktok</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-redbubble">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-handcart fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Redbubble</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-walmart">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-abstract-12 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Walmart</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-aliexpress">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-courier-express fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Aliexpress</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-zazzle">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-dropbox fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Zazzle</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-temu">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-purchase fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Temu</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-woocommerce">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-like-2 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Woocommerce</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pod-shopify">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-basket-ok fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Shopify</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isMobile && toggleSidebar && (
                <div
                    style={{ zIndex: "109" }}
                    className="drawer-overlay"
                    onClick={() => setToggleSidebar(!toggleSidebar)}
                ></div>
            )}
        </Fragment>
    );
}

// Navbar.propTypes = {
// };
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
