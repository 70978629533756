import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cx from "classnames";
import Loading from "components/Loading";
import Profiledetails from "./profiledetails/Profiledetails";
import Security from "./security/Security";
import Listprinters from "./listprinters/Listprinters";
import Activity from "./activity";
import Recharge from "./recharge"

function Main(props) {
    const [tab, setTab] = useState("profile_details");
    const { t } = useTranslation();
    var { role } = props.user.user;
    var { isLoading } = props.user;

    const onClick = (data) => {
        setTab(data);
    };

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-0 pb-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "profile_details",
                                })}
                                to="/admin/accounts"
                                onClick={(e) => {
                                    onClick("profile_details");
                                }}
                            >
                                {t("Profile Details")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "security",
                                })}
                                to="/admin/accounts"
                                onClick={(e) => {
                                    onClick("security");
                                }}
                            >
                                {t("Security")}
                            </Link>
                        </li>
                        {role === 0 || role === 1 ? (
                            <li className="nav-item mt-2">
                                <Link
                                    className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                        active: tab === "listprinters",
                                    })}
                                    to="/admin/accounts"
                                    onClick={(e) => {
                                        onClick("listprinters");
                                    }}
                                >
                                    {t("List Printers")}
                                </Link>
                            </li>
                        ) : (
                            ""
                        )}
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "activity",
                                })}
                                to="/admin/accounts"
                                onClick={(e) => {
                                    onClick("activity");
                                }}
                            >
                                {t("Activity")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "recharge",
                                })}
                                to="/admin/accounts"
                                onClick={(e) => {
                                    onClick("recharge");
                                }}
                            >
                                {t("Recharge")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "billing",
                                })}
                                to="/admin/accounts"
                                onClick={(e) => {
                                    onClick("billing");
                                }}
                            >
                                {t("Billing")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Loading isLoading={isLoading} />
            {tab === "profile_details" ? (
                <Profiledetails />
            ) : tab === "security" ? (
                <Security />
            ) : tab === "listprinters" ? (
                <Listprinters />
            ) : tab === "activity" ? (
                <Activity />
            ) : tab === "recharge" ? (
                <Recharge />
            ) : tab === "billing" ? (
                <>Billing</>
            ) : (
                <></>
            )}
        </Fragment>
    );
}

Main.propTypes = {};
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
