const initialState = {
	isLoading: false,
	listorders: [],
	all_orders_for_export: [],
	totalsize: 0,
};

function orderReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_ORDER':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LIST_ORDER':
			return {
				...state,
				listorders: action.payload.listorders,
				totalsize: action.payload.totalsize
			};
		case 'EXPORT_ORDER':
			return {
				...state,
				all_orders_for_export: action.payload.all_orders_for_export
			};
		default:
		  return state;
    }
}

export default orderReducer;
