import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import { loadUserTransition, rollbackTransition } from "actions/userActions";
import { formatterCreateAt } from "components/Function";

function Activity({ loadUserTransition, formatterCreateAt, rollbackTransition }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState(50);
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        loadUserTransition({ page, sizeperpage: sizePerPage, search: searchText }).then((data) => {
            setData(data?.userTransition || []);
            setTotalSize(data?.totalsize || 0);
            setLoading(false);
        });
    }, [loadUserTransition, searchText, page, sizePerPage]);

    const handleRollback = async (transitionId) => {
        setLoading(true);
        const check = await rollbackTransition(transitionId);
        if (check)
            await loadUserTransition({ page, sizeperpage: sizePerPage, search: searchText }).then((data) => {
                setData(data?.userTransition || []);
                setTotalSize(data?.totalsize || 0);
                setLoading(false);
            });
        setLoading(false);
    };

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "type",
            text: "Type",
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return (
                    <span
                        className={`badge badge-light-${
                            cell === "add" || cell === "buy" || cell === "auto_add" ? "success" : "danger"
                        }`}
                    >
                        {cell}
                    </span>
                );
            },
        },
        {
            dataField: "amount",
            text: t("Balance Change"),
            sort: true,
        },
        {
            dataField: "beforeBalance",
            text: t("Before Balance"),
            sort: true,
        },
        {
            dataField: "afterBalance",
            text: t("After Balance"),
            sort: true,
        },
        {
            dataField: "description",
            text: t("Content"),
            sort: true,
        },
        {
            dataField: "created_at",
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatExtraData: { page, sizePerPage, searchText },
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <Fragment>
                    {row.type === "buy" && !row.rollbackStatus && (
                        <button
                            className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={t("Rollback")}
                            onClick={() => handleRollback(row._id)}
                        >
                            <i className="ki-outline ki-arrows-circle fs-2"></i>
                        </button>
                    )}
                </Fragment>
            ),
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalSize || 50,
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    };

    return (
        <Fragment>
            <Loading isLoading={loading} />
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <div className="al-variants-table">
                        <ReactTable
                            columns={columns}
                            data={data}
                            groupButton={<></>}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit"
                            sizePerPageList={sizePerPageList}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { loadUserTransition, formatterCreateAt, rollbackTransition };

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
