import { useMemo, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getShops } from "actions/shopActions";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { scaleNumber } from "components/Function";
import Select from "react-select";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale, ChartDataLabels);

const OnHold = ({ data, scaleNumber, shop, getShops, reload }) => {
    const { list } = shop;
    const list_shop = list?.map((v, i) => {
        return { label: `${v?.name} (${v?.note}) - ${v?.region} - ${v?.code}`, value: v?._id };
    });
    const [filter_shop_code, setFilterShopCode] = useState("");
    const chartRef = useRef(null);
    const dataFormat = useMemo(() => {
        return {
            labels: ["Order delivered and awaiting settlement", "Waiting for package delivery"],
            datasets: [
                {
                    data: [
                        data.totalAwaitSettlement?.toFixed(2) || 0,
                        data.totalWaitingDelivered?.toFixed(2) || 0,
                    ],
                    backgroundColor: ["#FF6384", "#36A2EB"],
                    hoverBackgroundColor: ["#FF4567", "#3498DB"],
                },
            ],
        };
    }, [data]);

    useEffect(() => {
        getShops({ page: 1, sizeperpage: 9999 });
    }, [getShops]);

    const onChangeFilterShopCode = async (e) => {
        setFilterShopCode(e?.value || "");
        await reload(e?.value || "");
        chartRef.current.update();
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">OnHold</h3>
                </div>
            </div>
            <div className="card-body border-top p-9">
                <div className="row">
                    <div className="col-md-3">
                        <Select
                            isClearable
                            name="shop_code"
                            options={list_shop}
                            className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2 w-100"
                            classNamePrefix="select"
                            value={list_shop.filter(({ value }) => value === filter_shop_code)}
                            onChange={(e) => {
                                onChangeFilterShopCode(e);
                            }}
                            placeholder="Select a shop code ... "
                        />
                    </div>
                </div>
                {/* <h3 className="mt-8">Total OnHold: ${data?.totalOnHold?.toFixed(2)}</h3> */}
                <h3 className="mt-8">Total OnHold: ${+data?.totalAwaitSettlement?.toFixed(2) + +data.totalWaitingDelivered?.toFixed(2)}</h3>

                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <Doughnut
                            data={dataFormat}
                            ref={chartRef}
                            options={{
                                responsive: true,
                                plugins: {
                                    title: {
                                        display: true,
                                        text: "On Hold",
                                    },
                                    legend: {
                                        display: true,
                                        position: "top",
                                    },
                                    datalabels: {
                                        color: "#fff", // Màu sắc của các số liệu
                                        font: {
                                            weight: "bold", // Kiểu chữ cho số liệu
                                            size: 16, // Kích thước chữ
                                        },
                                        formatter: (value) => value, // Hàm định dạng số liệu, có thể tùy chỉnh
                                    },
                                },
                                aspectRatio: 1,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    shop: state.shop,
});
const mapDispatchToProps = { scaleNumber, getShops };

export default connect(mapStateToProps, mapDispatchToProps)(OnHold);
