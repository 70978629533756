import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// import Loading from "components/Loading";
import { getListPackageActive } from "actions/packageActions";

const Packages = ({ getListPackageActive }) => {
    const { t } = useTranslation();
    // const [isLoading, setIsLoading] = useState(false);
    // const [packages, setPackages] = useState([]);

    // useEffect(() => {
    //     setIsLoading(true);
    //     getListPackageActive().then((data) => {
    //         setPackages(data?.listpackages || []);
    //         setIsLoading(false);
    //     });
    // }, [getListPackageActive]);

    return (
        <div className="mt-20">
            <div className="landing-curve landing-dark-color ">
                <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                        fill="currentColor"
                    />
                </svg>
            </div>
            <div className="landing-dark-bg">
                {/* <Loading isLoading={isLoading} /> */}
                <div className="container py-8">
                    <div className="d-flex flex-column container">
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bold mb-5 text-white">{t("Choose Your Package")}</h1>
                            <div className="fw-semibold fs-5 text-white">
                                {t("If you need more info about our pricing, please check")}{" "}
                                <span role="button" className="link-primary fw-bold">
                                    {t("Pricing Guidelines")}
                                </span>
                                .
                            </div>
                        </div>
                        <div className="row g-10">
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light py-15 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-gray-900 mb-5 fw-bolder">1 Month</h1>
                                            <div className="text-gray-600 fw-semibold mb-5">For Team</div>
                                            <div className="text-center">
                                                <span className="mb-2 text-primary">$</span>
                                                <span className="fs-3x fw-bold text-primary">2</span>
                                                <span className="fs-7 fw-semibold opacity-50">/ Shop</span>
                                            </div>
                                        </div>
                                        <div className="w-100 mb-10">
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Shop
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Promotion
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Product
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Listing Product
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Orders
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                        </div>
                                        <a href="/admin/package" type="button" className="btn btn-sm btn-primary">
                                            Buy
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-white mb-5 fw-boldest">1 Year</h1>
                                            <div className="text-white opacity-75 fw-semibold mb-5">For Team</div>
                                            <div className="text-center">
                                                <span className="mb-2 text-white">$</span>
                                                <span className="fs-3x fw-bold text-white">1</span>
                                                <span className="fs-7 fw-semibold text-white opacity-75" >/ Shop</span>
                                            </div>
                                        </div>
                                        <div className="w-100 mb-10">
                                            <div className="d-flex flex-stack mb-5">
                                                <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Manager Shop</span>
                                                <i className="ki-outline ki-check-circle fs-1 text-white"></i>
                                            </div>
                                            <div className="d-flex flex-stack mb-5">
                                                <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Manager Promotion</span>
                                                <i className="ki-outline ki-check-circle fs-1 text-white"></i>
                                            </div>
                                            <div className="d-flex flex-stack mb-5">
                                                <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Manager Product</span>
                                                <i className="ki-outline ki-check-circle fs-1 text-white"></i>
                                            </div>
                                            <div className="d-flex flex-stack mb-5">
                                                <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Listing Product</span>
                                                <i className="ki-outline ki-check-circle fs-1 text-white"></i>
                                            </div>
                                            <div className="d-flex flex-stack">
                                                <span className="fw-semibold fs-6 text-white opacity-75">Manager Orders</span>
                                                <i className="ki-outline ki-cross-circle fs-1 text-white"></i>
                                            </div>
                                        </div>
                                        <a href="/admin/package" className="btn btn-color-primary btn-active-light-primary btn-light">Buy</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light py-15 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-gray-900 mb-5 fw-bolder">6 Month</h1>
                                            <div className="text-gray-600 fw-semibold mb-5">For Team</div>
                                            <div className="text-center">
                                                <span className="mb-2 text-primary">$</span>
                                                <span className="fs-3x fw-bold text-primary">1.5</span>
                                                <span className="fs-7 fw-semibold opacity-50">/ Shop</span>
                                            </div>
                                        </div>
                                        <div className="w-100 mb-10">
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Shop
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Promotion
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Product
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Listing Product
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Orders
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                        </div>
                                        <a href="/admin/package" type="button" className="btn btn-sm btn-primary">
                                            Buy
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing-curve landing-dark-color ">
                <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                        fill="currentColor"
                    />
                </svg>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ user: state?.user?.user });
const mapDispatchToProps = { getListPackageActive };

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
